import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import { Picture } from 'components/Picture';
import { TypeIconInline } from 'components/TypeIconInline';
import { Unibrow } from 'components/Unibrow';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { author as authorPropType, slide as slidePropType } from 'lib/CustomPropTypes';
import { getTeaseByline } from 'lib/teaseUtils';
import { imageAltForItem } from 'lib/imageAlt';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { RECIRC_SAVE_BUTTON } from 'lib/brandFeatures';
import { Save } from 'components/SocialShareMenu/Save';
import { useVertical } from 'lib/Hooks';
import get from 'lodash.get';

import RecipeDetails from 'components/Recipe/RecipeDetails';
import styles from './styles.module.scss';

const ContentLinkCard = ({ content }) => {
  const { vertical } = useVertical();
  const { t } = useTranslation();

  if (!content) {
    return null;
  }

  const {
    teaseImage,
    authors,
    type = 'slideshow',
    id,
  } = content;

  const {
    cookTime, prepTime, servingSize, yield: yields,
  } = content;

  const ctaTxt = type === 'recipe' ? 'Get The Recipe' : 'Slideshow';

  const primaryUrl = get(content, 'url.primary', '');
  const headlinePrimary = get(content, 'headline.primary', '');
  const imgAlt = imageAltForItem(content) || headlinePrimary;

  const {
    url: teaseImageUrl,
    height: teaseImageHeight,
    width: teaseImageWidth,
  } = teaseImage || {};

  const hasSaveButton = getFeatureConfigForBrand(
    RECIRC_SAVE_BUTTON,
    vertical,
  );
  return (
    <div className={styles.card} data-test="article-card" data-testid="article-card">
      {teaseImageUrl && (
        <div
          className={styles.imageWrapper}
          data-test="article-card-image"
          data-testid="article-card-image"
        >
          <div className={styles.tiltWrapper}>
            <Link to={primaryUrl}>
              <Picture
                url={teaseImageUrl.primary}
                responsiveFlavors={{
                  s: AIMS_FLAVORS.FOCAL_600x300,
                  m: AIMS_FLAVORS.FOCAL_720x360,
                  l: AIMS_FLAVORS.FOCAL_920x460,
                  xl: AIMS_FLAVORS.FOCAL_860x430,
                }}
                className={styles.coverImage}
                alt={imgAlt}
                lazyloaded
                originalHeight={teaseImageHeight}
                originalWidth={teaseImageWidth}
              />
            </Link>
            <div className={styles.byline}>
              {getTeaseByline(teaseImage)}
            </div>
          </div>
          {hasSaveButton ? (
            <Save
              contentId={id}
              contentType={type}
              options={{ isThumbnail: true, showCTA: true }}
              additionalClasses={styles.save}
            />
          ) : null}
        </div>
      )}
      <div
        className={classNames(
          'content-link-card',
          styles.txtWrapper,
          {
            [styles.withDek]: type !== 'recipe',
            [styles.noImg]: !teaseImageUrl,
          },
        )}
        data-testid="article-card-text"
      >
        <Unibrow
          unibrow={{
            url: {
              primary: primaryUrl,
            },
            override: t(ctaTxt),
          }}
          hasDefaultTextStyle
        >
          {content.slides?.length && (
            <span className={styles.photos} data-testid="article-card-photo-count">
              &nbsp;&nbsp;
              {content.slides.length}
              {' '}
              {t('photos')}
            </span>
          )}
        </Unibrow>
        <h3 className={styles.title}>
          <Link
            to={primaryUrl}
            data-test="article-card-text-link"
            data-testid="article-card-text-link"
          >
            <TypeIconInline type={type} size="medium" />
            {headlinePrimary}
          </Link>
        </h3>
        {type === 'recipe' ? (
          <>
            <RecipeDetails
              type={type}
              cookTime={cookTime}
              prepTime={prepTime}
              servingSize={servingSize}
              yields={yields}
            />
            <div className={styles.author} data-testid="article-card-author">
              {authors && authors[0]?.name}
            </div>
          </>
        ) : (
          <div className={styles.description} data-test="article-card-description" data-testid="article-card-description">
            {content.description?.primary}
          </div>
        )}
      </div>
    </div>
  );
};

ContentLinkCard.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    headline: PropTypes.shape({
      primary: PropTypes.string,
    }),
    authors: PropTypes.arrayOf(authorPropType),
    teaseImage: PropTypes.shape({
      url: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
    description: PropTypes.shape({
      primary: PropTypes.string,
      seo: PropTypes.string,
    }),
    slides: PropTypes.arrayOf(slidePropType),
    id: PropTypes.string.isRequired,
    cookTime: PropTypes.string,
    prepTime: PropTypes.string,
    servingSize: PropTypes.string,
    yield: PropTypes.string,
  }).isRequired,
};

export { ContentLinkCard };
