import React, { useEffect, useRef } from 'react';
import get from 'lodash.get';
import { widget as widgetPropType } from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';
import { decodeString } from 'lib/stringUtils';
import getAllRegexMatches from 'lib/getAllRegexMatches';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';
import { useInView } from 'react-intersection-observer';

import MenuEmbed from './MenuEmbed/index';

const scriptTagRegex = /<script[^>]+?src="([^"]+?)"[^>]*?><\/script>/gi;
const scriptsWithoutSrcs = /<script>([^]+?)<\/script>/gi;

export const AdvancedEmbed = function AdvancedEmbed({ widget }) {
  const shouldInitialize = useRef(true);
  const { ref, inView, entry = {} } = useInView({
    threshold: 0,
    rootMargin: '0px 0px 125% 0px',
  });

  useEffect(() => {
    async function init() {
      const html = decodeString(get(widget, 'properties[\'embed-code\']', ''));

      // embed-code not found or not valid
      if (html && typeof html === 'string') {
        const scriptTags = getAllRegexMatches(scriptTagRegex, html);

        await Promise.all(scriptTags.map(([, scriptSrc]) => loadScript(
          scriptSrc,
          {
            location: entry.target || 'head',
            attributes: { defer: true },
            force: true,
          },
        )));

        const domFragment = document.createDocumentFragment();

        const scriptTagsWithoutSrcs = getAllRegexMatches(scriptsWithoutSrcs, html);

        scriptTagsWithoutSrcs.forEach(([, scriptContent]) => {
          const scriptTag = document.createElement('script');

          scriptTag.innerHTML = scriptContent;

          domFragment.appendChild(scriptTag);
        });

        if (entry.target) {
          entry.target.appendChild(domFragment);
        }
      }
    }

    if (inView && shouldInitialize.current) {
      shouldInitialize.current = false;
      init();
    }
  }, [inView]);

  const embedCode = decodeString(get(widget, 'properties[\'embed-code\']', ''));

  // embed-code not found or not valid
  if (!embedCode || typeof embedCode !== 'string') {
    return null;
  }

  const nodeAssetType = /^https?:\/\/nodeassets.nbcnews/.test(embedCode);
  if (nodeAssetType) {
    return <iframe loading="lazy" width="100%" height="500px" src={embedCode} title="embed" />;
  }

  const html = decodeString(htmlSetIframeLoadingAttributes(embedCode, 'lazy'));

  const menuEmbedType = (
    html.match(/class=["“”]menu-embed["“”]/)
    && html.match(/embedId=["“”][0-9]+["“”]/)
  );
  if (menuEmbedType) {
    return <MenuEmbed className="menu-embed" html={html} />;
  }

  const vimeoEmbedType = /vimeo.com/.test(html);
  if (vimeoEmbedType) {
    const vimeoEmbedHtml = html.replace(/\s*(width|height)=["']([^"]+)["']/g, '');

    return (
      <div className="embed-widget__use-presentation embed-widget__vimeo">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: vimeoEmbedHtml }}
        />
      </div>
    );
  }

  const htmlWithoutScriptTags = (
    html
      .replace(scriptTagRegex, '')
      .replace(scriptsWithoutSrcs, '')
  );

  return (
    <div
      ref={ref}
      className="embed-widget__use-presentation"
      data-test="advanced-embed"
      data-testid="advanced-embed"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlWithoutScriptTags }}
    />
  );
};

AdvancedEmbed.propTypes = {
  widget: widgetPropType.isRequired,
};
