import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { getProductOfferPricesAndAvailability } from 'lib/productOfferPriceHelpers';
import { UNIVERSAL_CHECKOUT_ENABLED } from 'lib/brandFeatures';
import { stub as $t } from '@nbcnews/analytics-framework';

$t('register', 'mbt_ecommerce_shop_button', { allowDuplicate: true });

/**
 * @typedef {Object} Offer - Offer Associated to Ecommerce Product
 * @property {Object} Seller - Seller of Offer
 */

/**
 * @typedef {Object} Product - Ecommerce Product
 * @property {string} name - Name of Product
 * @property {Offer[]} [offers] - Offers associated to Product
 */

/**
 * gets the primary offer of product
 *
 * @param {Product} - product
 */
export const getPrimaryOffer = (product) => product?.offers?.[0];

/**
 * gets tracking params for Ecommerce Affiliate Link Click Tracking
 *
 * @typedef {Object} GetTrackingParamsProps
 * @property {string} vertical
 * @property {Product} product - Ecommerce Product
 *
 * @param {GetTrackingParamsProps}
 * @return {{ name: string, listPrice: Object, seller: string }}
 */
const getTrackingParams = ({ vertical, product }) => {
  const offer = getPrimaryOffer(product);
  const { list } = getProductOfferPricesAndAvailability(offer);

  let seller = offer?.seller?.name;

  if (getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_ENABLED, vertical)) {
    seller = 'Comprar';
  }

  return {
    name: product.name,
    listPrice: list,
    seller,
  };
};

/**
 * Fires `mbt_ecommerce_shop_button` Track event
 *
 * @param {{ vertical: string, product: Product }}
 */
export const trackEcommerceShopClick = ({ vertical, product }) => {
  $t('track', 'mbt_ecommerce_shop_button', {
    action: 'itemClick',
    ...getTrackingParams({ vertical, product }),
  });
};
