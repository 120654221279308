import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';

import styles from './styles.module.scss';


/**
 * Text content to be rendered in the button
  * @param {string} [size] - size to render button
  * @param {function} [onShowMoreClick] - cb to show data and hide button on click
  * @returns {React.ReactElement}
 */

const ButtonShowMore = ({ onShowMoreClick, size }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onShowMoreClick(true);
  };


  return (
    <div
      className={styles.showMoreButtonWrapper}
    >
      <Button
        displayType="expand-down"
        tiltClasses={styles.showMoreButtonTilt}
        additionalClasses={styles.showMoreButton}
        onClick={handleClick}
        title={`${t('Show more')}`}
        size={size}
      />
    </div>
  );
};

ButtonShowMore.propTypes = {
  onShowMoreClick: PropTypes.func,
  size: PropTypes.string,
};

ButtonShowMore.defaultProps = {
  onShowMoreClick: Function.prototype,
  size: '',
};

export { ButtonShowMore };
