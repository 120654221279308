import React from 'react';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link/LinkIfHref';
import './styles.themed.scss';

import {
  decodeString,
  generateElementKey,
} from 'lib/stringUtils';

const block = 'blockquote';

function BlockQuote({ text = '', attribution, source }) {
  return (
    <section className={block}>
      <blockquote
        className={`${block}__quote`}
        cite={attribution}
      >
        {decodeString(text).split(/[\n\r]+/).map((graph) => (
          <p key={generateElementKey(graph)}>
            {graph}
          </p>
        ))}
        <small className={`${block}__source`}>
          <LinkIfHref
            data-test="blockquote-source"
            href={source}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span data-test="blockquote-attribution">
              {decodeString(attribution)}
            </span>
          </LinkIfHref>
        </small>
      </blockquote>
    </section>
  );
}

BlockQuote.propTypes = {
  attribution: PropTypes.string,
  source: PropTypes.string,
  text: PropTypes.string.isRequired,
};

BlockQuote.defaultProps = {
  attribution: null,
  source: null,
};

export default BlockQuote;
