import React from 'react';
import PropTypes from 'prop-types';
import { product as ProductPropType } from 'lib/CustomPropTypes';
import { RouteContext } from 'lib/ContextTypes';
import { ExpandableList } from 'components/ExpandableList';
import { getDataActivityMapID } from 'lib/articleUtils';
import { useInView } from 'react-intersection-observer';
import { stub as $t } from '@nbcnews/analytics-framework';
import { Card as QuickTakeCard } from './Card';

$t('register', 'mbt_quicktake_click');
$t('register', 'mbt_quicktake_inview');

export function QuickTake({
  title, subTitle, products, vertical,
}) {
  const route = React.useContext(RouteContext);
  const isAmp = route.path.indexOf('embedded-pkg') !== -1;

  const { ref, inView } = useInView({
    triggerOnce: true,
  }) || {};

  React.useEffect(() => {
    if (inView || isAmp) {
      $t('track', 'mbt_quicktake_inview');
    }
  }, [inView, isAmp]);

  const cards = products
    .map((product) => (
      <QuickTakeCard product={product} vertical={vertical} />
    ))
    .filter((c) => Boolean(c));

  if (cards.length === 0) {
    return null;
  }

  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'quick-take',
      pageRegion: 'article-body',
      componentTitle: title,
    },
  );

  return (
    <div
      data-activity-map={dataActivityMapID}
      ref={ref}
    >
      <ExpandableList
        cards={cards}
        trackDataAnalyticsClicks={(callToAction) => $t('track', 'mbt_quicktake_click', { action: callToAction })}
        title={title}
        subtitle={subTitle}
      />
    </div>
  );
}

QuickTake.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  products: PropTypes.arrayOf(ProductPropType.isRequired).isRequired,
  vertical: PropTypes.string.isRequired,
};

QuickTake.defaultProps = {
  title: '',
  subTitle: '',
};
