import React from 'react';
import getConfig from 'next/config';

function useFetch(curationPath, opts = {}) {
  const {
    publicRuntimeConfig: {
      API_URL_BENTO_PUBLIC,
    },
  } = getConfig();

  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    fetch(`${API_URL_BENTO_PUBLIC}/${curationPath}`, opts)
      .then((res) => res.json())
      .then((res) => {
        setResponse(res.data);
        setLoading(false);
      })
      .catch(() => {
        setHasError(true);
        setLoading(false);
      });
  }, [curationPath]);

  return [
    response,
    loading,
    hasError,
  ];
}

export { useFetch };
