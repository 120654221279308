import React, { useEffect } from 'react';

export function TaboolaRecoReel() {
  // the code in this useEffect was provided by taboola, see details in ticket:
  // https://nbcnewsdigital.atlassian.net/browse/BENTO-22253

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._taboola = window._taboola || [];
    // eslint-disable-next-line no-underscore-dangle
    window._taboola.push({
      mode: 'reco-reel',
      container: 'taboola-mid-article-reco-reel',
      placement: 'Mid Article Reco Reel',
      target_type: 'mix',
    });
  }, []);

  return (
    <div id="taboola-mid-article-reco-reel" />
  );
}
