import React from 'react';

function getDebugComponent(item, i) {
  return (
    <pre
      key={i}
      style={{
        backgroundColor: '#333',
        borderRadius: '5px',
        padding: '3em',
      }}
    >
      {JSON.stringify(item, null, 2)}
    </pre>
  );
}

export { getDebugComponent };
