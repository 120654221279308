import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Carousel from 'nuka-carousel';
import { stub as $t } from '@nbcnews/analytics-framework';

import Breakpoints from 'lib/Breakpoints';
import { product as productPropType } from 'lib/CustomPropTypes';
import { getDateTime } from 'lib/DateTime';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { UNIVERSAL_CHECKOUT_ENABLED } from 'lib/brandFeatures';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import buildAffiliateLink from 'lib/buildAffiliateLink';
import { getDataActivityMapID } from 'lib/articleUtils';
import { trackEcommerceShopClick } from 'lib/trackEcommerceShopClick';

import Link from 'components/Link';
import { Picture } from 'components/Picture';
import ShopButtonInfo from 'components/ShopButtonInfo';
import { Save } from 'components/SocialShareMenu/Save';
import { getProductOfferPricesAndAvailability } from 'lib/productOfferPriceHelpers';
import { PRODUCT } from 'lib/myNewsConstants';
import { withViewportVisibility } from 'lib/Hooks/withViewportVisibility';
import { Container } from './Container';
import { PromotionalMedia } from './PromotionalMedia';
import { ProsCons } from './ProsCons';

import './styles.themed.scss';

const block = 'listicleProduct';

const ENCODER_STOP_DATE = 'Wed Apr 15 2020 00:00:00';

class ListicleProduct extends React.Component {
  static propTypes = {
    addClassNames: PropTypes.string,
    articlePublishDate: PropTypes.string.isRequired,
    product: productPropType.isRequired,
    size: PropTypes.string,
    vertical: PropTypes.string.isRequired,
  };

  static contextTypes = {
    canonicalUrl: PropTypes.string,
  };

  static defaultProps = {
    addClassNames: '',
    size: 'medium',
  };

  constructor(props) {
    super(props);

    const {
      product: {
        teaseImage,
      },
    } = this.props;

    this.state = {
      currentMedia: teaseImage?.url?.primary,
      currentMediaWidth: teaseImage?.width,
      currentMediaHeight: teaseImage?.height,
      currSlideIdx: 0,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: Breakpoints.isS() });
    Breakpoints.getSmallMQL()?.addListener(this.onBreakpointChange);
    $t('register', 'mbt_ecommerce_shop_button', { allowDuplicate: true });
  }

  componentWillUnmount() {
    Breakpoints.getSmallMQL()?.removeListener(this.onBreakpointChange);
  }

  onBreakpointChange = () => {
    this.setState({ isMobile: Breakpoints.isS() });
  }

  changeCurrentMedia = (currentMedia, currSlideIdx) => this.setState(
    {
      currentMedia,
      currSlideIdx,
    },
  );

  getPrimaryOffer = () => {
    const {
      product,
    } = this.props;

    return product?.offers?.[0];
  }

  getProductLink = (externalUrl) => {
    const {
      articlePublishDate,
      vertical,
    } = this.props;

    const { canonicalUrl } = this.context;

    let productLink = externalUrl ?? this.getPrimaryOffer()?.externalUrl;

    if (!getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_ENABLED, vertical)) {
      const fetchedEncoderStopDate = getDateTime(ENCODER_STOP_DATE);
      const fetchedCurrentArticlePublishDate = getDateTime(articlePublishDate);
      if (fetchedCurrentArticlePublishDate < fetchedEncoderStopDate) {
        productLink = buildAffiliateLink({ url: productLink, canonicalUrl });
      }
    }

    return productLink;
  }

  picture = (media) => {
    const {
      product: {
        id: productId,
        name,
        teaseImage,
      },
      size,
      product,
      vertical,
    } = this.props;

    const {
      currentMedia,
      currentMediaWidth,
      currentMediaHeight,
      isMobile,
    } = this.state;

    const mediaUrl = media?.url?.primary;
    const mediaWidth = media?.width;
    const mediaHeight = media?.height;

    const isLarge = size === 'large';
    const isOde = isLarge;

    const sizes = isLarge
      ? {
        s: AIMS_FLAVORS.FOCAL_560x560,
      }
      : {
        s: AIMS_FLAVORS.FIT_320w,
        m: AIMS_FLAVORS.FIT_360w,
        l: AIMS_FLAVORS.FIT_260w,
        xl: AIMS_FLAVORS.FIT_260w,
      };
    const flavor = isLarge ? 'focal' : 'fit';

    const width = isLarge ? 560 : mediaWidth || currentMediaWidth;
    const height = isLarge ? 560 : mediaHeight || currentMediaHeight;

    const pictureLinkClasses = classNames(
      `${block}__pictureLink`,
      'listicle-product__picture-link',
      {
        db: !mediaUrl,
        'db-m dn': !isMobile,
        'listicle-product__picture-link--isOde': isOde,
      },
    );

    const productLink = this.getProductLink();

    return (
      <div className={pictureLinkClasses}>
        <Link
          className="w-100"
          href={productLink}
          rel="sponsored noopener noreferrer"
          data-testid="listicle-product-picture-link"
          target="_blank"
          data-product-id={productId}
          onClick={() => trackEcommerceShopClick({ vertical, product })}
        >
          <Picture
            alt={teaseImage?.altText || name}
            flavor={flavor}
            lazyloaded
            originalHeight={height}
            originalWidth={width}
            responsiveFlavors={sizes}
            url={mediaUrl || currentMedia}
          />
        </Link>
        <Save
          contentId={productId}
          contentType={PRODUCT}
          options={{ isThumbnail: true, showCTA: true }}
        />
      </div>
    );
  }

  mobileMediaSlide = () => {
    const {
      product: {
        promotionalMedia,
      },
    } = this.props;

    const { currSlideIdx, isMobile } = this.state;

    return (
      <div
        className={classNames('listicle-product__mobile-media', { dn: isMobile })}
        data-test="listicle-media-wrapper"
        data-testid="listicle-media-wrapper"
      >
        <Carousel
          afterSlide={(nextIdx) => this.setState({ currSlideIdx: nextIdx })}
          decorators={[]}
          slideIndex={currSlideIdx}
          withoutControls
        >
          {promotionalMedia.map((image) => (
            image?.url?.primary && (
              <div
                className="listicle-product__mobile-media-item"
                key={image.url.primary}
                data-test="listicle-media-item"
                data-testid="listicle-media-item"
              >
                {this.picture(image)}
              </div>
            )
          ))}
        </Carousel>
      </div>
    );
  };

  renderOffers = () => {
    const {
      product: {
        id: productId,
        name,
        offers,
      },
      articlePublishDate,
      vertical,
    } = this.props;

    return Array.isArray(offers) && offers.map((offer) => {
      if (!offer) {
        return null;
      }

      const vendorUrl = this.getProductLink(offer.externalUrl);

      let seller = offer.seller?.name;
      if (getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_ENABLED, vertical)) {
        seller = 'Comprar';
      }

      const { list, sale } = getProductOfferPricesAndAvailability(offer);

      return (
        <ShopButtonInfo
          productId={productId}
          key={vendorUrl}
          additionalStyles={{
            vendor: 'listicle-product__vendor',
            cost: 'listicle-product__cost',
            shop: 'listicle-product__button',
          }}
          articlePublishDate={articlePublishDate}
          listPrice={list}
          name={name}
          productUrl={vendorUrl}
          salePrice={sale}
          seller={seller}
          vertical={vertical}
        />
      );
    });
  }

  renderUniCheckoutSeller = (vertical, offers) => (
    getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_ENABLED, vertical) && offers?.[0]?.seller
      ? (
        <p className="listicle-product__seller-name">
          Vendido por&nbsp;
          {offers[0].seller.name}
        </p>
      )
      : null
  )

  render() {
    const {
      addClassNames,
      product: {
        id: productId,
        description,
        name,
        offers,
        promotionalMedia,
        prosAndCons,
      },
      size,
      vertical,
      product,
    } = this.props;

    const productLink = this.getProductLink();

    const { currSlideIdx, isMobile } = this.state;

    const { primary: primaryDescription } = description || {};
    const { pros = {}, cons = {} } = prosAndCons || {};

    const isLarge = size === 'large';
    const hasPromotionalMedia = !!promotionalMedia?.length && isLarge;
    const hasProsCons = pros?.items?.length > 0;

    return (
      <div id={productId} className={addClassNames}>
        <Container isLarge={isLarge} productName={name}>
          <div
            className={
              classNames('listicle-product__border', { 'df flex-column flex-row-m': isLarge, hasProsCons })
            }
          >
            { hasPromotionalMedia && !isMobile && (
              <PromotionalMedia
                media={promotionalMedia}
                selectMedia={this.changeCurrentMedia}
                selectedIdx={currSlideIdx}
                additionalStyles={{
                  container: 'dn df-m flex-column w-4-m flex-grow-0 flex-shrink-0',
                  item: 'mb2',
                }}
              />
            )}
            <div
              className={classNames('df flex-column items-stretch listicle-product__card-container',
                {
                  'flex-row-l flex-grow-1': isLarge,
                  'flex-row-m': !isLarge,
                  'pb6-m': !hasProsCons,
                })}
            >
              { this.picture() }
              { isLarge && this.mobileMediaSlide() }
              <div
                className={
                  classNames(
                    `${block}__productCardInfo pt5 flex-grow-1`,
                    { pb5: !hasProsCons },
                    { 'pt0-m pb0-m': !isLarge },
                    'listicle-product__card-info',
                  )
                }
              >
                { hasPromotionalMedia && isMobile && (
                  <PromotionalMedia
                    media={promotionalMedia}
                    selectMedia={this.changeCurrentMedia}
                    selectedIdx={currSlideIdx}
                    isMobile={isMobile}
                    additionalStyles={{
                      container: 'dn-m df mb7',
                    }}
                  />
                )}

                { this.renderUniCheckoutSeller(vertical, offers) }

                <a
                  className={`${block}__productTitleLink`}
                  href={productLink}
                  data-testid="listicle-product-title-link"
                  rel="sponsored noopener noreferrer"
                  target="_blank"
                  data-product-id={productId}
                  onClick={() => {
                    trackEcommerceShopClick({ vertical, product });
                  }}
                >
                  <h2 className={classNames('listicle-product__headline', {
                    'listicle-product__headline--no-description': !primaryDescription,
                  })}
                  >
                    {name}
                  </h2>
                  { primaryDescription && (
                    <p
                      data-test="listicle__description"
                      data-testid="listicle__description"
                      className={classNames('listicle-product__description', {
                        'listicle-product__description--large': isLarge,
                      })}
                    >
                      {primaryDescription}
                    </p>
                  )}
                </a>
                { this.renderOffers() }
              </div>
            </div>
          </div>
          {hasProsCons && (
            <ProsCons
              pros={pros}
              cons={cons}
              isOde={isLarge}
              dataActivityMapId={getDataActivityMapID(
                {
                  componentName: 'listicle-product',
                  pageRegion: 'article-body',
                  componentTitle: name,
                },
              )}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default ListicleProduct;

export const getIdForLazyListicleProduct = (props, shouldShowComponent, defaultId) => {
  const {
    product: { id },
  } = props;
  return shouldShowComponent ? defaultId || id : undefined;
};


export const LazyListicleProduct = withViewportVisibility(
  ListicleProduct,
  undefined,
  getIdForLazyListicleProduct,
);
