import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

export function ReportUnmounting({ children, onUnmount }) {
  useLayoutEffect(() => () => {
    onUnmount?.();
  }, []);
  return children;
}

ReportUnmounting.propTypes = {
  children: PropTypes.node,
  onUnmount: PropTypes.func,
};

ReportUnmounting.defaultProps = {
  children: undefined,
  onUnmount: undefined,
};
