import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import loadScript from 'lib/loadScript';

export class StorifyWidget extends React.Component {
  static propTypes = {
    html: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { html: url } = this.props;
    loadScript(`//${url}`, { attributes: { defer: true } });
  }

  render() {
    const { html: url } = this.props;
    const classes = classNames(
      'widget',
      'widget_trusted',
      'widget_iframe-overflow',
      'storify',
      'embed-widget__use-presentation',
    );
    return (
      <div className={classes}>
        <iframe
          loading="lazy"
          title="storify"
          src={`//${url}/embed`}
          width="100%"
          height="750"
          frameBorder="no"
          allowTransparency="true"
        />
        <noscript>
          [
          <a href="//{{url}}" target="_blank" rel="noopener noreferrer">
            {i18next.t('View the story on Storify')}
          </a>
          ]
        </noscript>
      </div>
    );
  }
}
