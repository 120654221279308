import findLastIndex from 'lodash.findlastindex';
import findIndex from 'lodash.findindex';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { TABOOLA_RECO_REEL } from 'lib/brandFeatures';

/**
 * Article Body item representing a Taboola Reco Reel module
 * @constant {string} TaboolaRecoReelModuleType
 * @readonly
 */
export const TYPE = 'taboolaRecoReel';

/**
 * Gets the index corresponding to the `n`th paragraph starting from the top of the body array. Returns `-1`
 * if it couldn't be found.
 *
 * @param {Object} index
 * @param {Array<(BodyElement|null|undefined)>} index.body Body arrays from the API
 * @param {Number} index.displayAfter the number of paragraphs from where to insert Reco Reel
 *
 * @return {Number} the index within the `body` array corresponding to the `n`th paragraph from the
 * beginning of the array
 */
export function getNthGrafFromStartIndex(index) {
  // Destructuring placement
  const { body, displayAfter: n } = index;
  // Prevent Showing as first item
  if (n === 0) {
    return body.length;
  }
  // Process Markup from direction
  let paragraphCount = 0;
  return findIndex(body, (item) => {
    if (item?.type === 'markup' && item?.element === 'p') {
      paragraphCount += 1;
    }
    return paragraphCount === n;
  });
}

/**
 * Gets the index corresponding to the `n`th paragraph starting from the bottom of the body array. Returns `-1`
 * if it couldn't be found.
 *
 * @param {Object} index
 * @param {Array<(BodyElement|null|undefined)>} index.body Body arrays from the API
 * @param {Number} index.displayAfter the number of paragraphs from where to insert Reco Reel
 *
 * @return {Number} the index within the `body` array corresponding to the `n`th paragraph from the
 * end of the array
 */
export function getNthGrafFromEndIndex(index) {
  // Destructuring placement
  const { body, displayAfter: n } = index;
  // Prevent Showing as first item
  if (n === 0) {
    return body.length;
  }
  // Process Markup from direction
  let paragraphCount = 0;
  return findLastIndex(body, (item) => {
    if (item?.type === 'markup' && item?.element === 'p') {
      paragraphCount += 1;
    }
    return paragraphCount === n;
  });
}

/**
 * Inserts a Taboola Reco Reel item into the body of an article. Tries to put the module before the
 * third paragraph from the end of a story, but moves it down one (increases index by one) if the
 * preceding item is a `small` presentation embeddedWidget
 * @param  {Object} options
 * @param  {Boolean} options.adsEnabled whether ads are enabled for the current article
 * @param  {Boolean} options.breakingNews whether the article is breaking news or not
 * @param  {Boolean} options.isLiveBlog whether the article is a live blog
 * @param  {Boolean} options.taboolaRecoReelEnabled whether to insert the module or not, based on
 * the LaunchDarkly flag
 * @return {Function}
 */
export function insertTaboolaRecoReel({
  adsEnabled,
  breakingNews,
  isLiveBlog,
  taboolaRecoReelEnabled,
  vertical,
}) {
  if (!taboolaRecoReelEnabled || !adsEnabled || breakingNews || isLiveBlog) {
    /**
     * Identity function
     * @param  {Array<(BodyElement|null|undefined)>} body
     * @return {Array<(BodyElement|null|undefined)>}
     */
    return (body) => body;
  }

  /**
   * Function used in a `pipe` pipeline to add the Taboola Reco Reel module
   * @param  {Array<(BodyElement|null|undefined)>} body Body of the article
   * @return {Array<(BodyElement|null|undefined)>}
   */
  return (body) => {
    // Count # of p tags in body
    const numberOfParagraphsInBody = body.reduce(
      (count, item) => count + (item?.type === 'markup' && item?.element === 'p' ? 1 : 0),
      0,
    );

    const {
      onlyIfParagraphsCount,
      displayAfter,
      inverted,
    } = getFeatureConfigForBrand(TABOOLA_RECO_REEL, vertical);

    // Added only if article has more than x amount of P tags
    if (numberOfParagraphsInBody < onlyIfParagraphsCount) {
      return body;
    }

    // Prepare Placement Data
    const index = {
      body,
      displayAfter,
    };

    // Get Reco Reel Placement
    let slicePoint = inverted ? getNthGrafFromEndIndex(index) : getNthGrafFromStartIndex(index);

    if (slicePoint === -1) {
      return body;
    }

    // Correct Placement depending on direction
    slicePoint = inverted ? slicePoint : slicePoint + 1;

    // Presentation Size
    const precedingItem = body[slicePoint - 1];
    slicePoint = precedingItem?.presentation?.size === 'small'
      ? slicePoint + 1
      : slicePoint;

    return [
      ...body.slice(0, slicePoint),
      { type: TYPE },
      ...body.slice(slicePoint),
    ];
  };
}

/**
 * Enable Taboola Reco Reel by Features Brand
 * @returns {Boolean}
 */
export function getTaboolaRecoReelEnabled(vertical) {
  const taboolaRecoReelEnabled = getFeatureConfigForBrand(TABOOLA_RECO_REEL, vertical);
  if (taboolaRecoReelEnabled) return true;
  return taboolaRecoReelEnabled;
}
