import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  pageRegion as pageRegionPropType,
  related as relatedPropType,
} from 'lib/CustomPropTypes';
import { stub as $t } from '@nbcnews/analytics-framework';
import IntObserver from 'components/IntObserver';
import { VerticalContext } from 'lib/ContextTypes';
import { getDataActivityMapID } from 'lib/articleUtils';
import RelatedItem from './Item';

import './styles.themed.scss';

class Related extends React.Component {
  static contextType = VerticalContext;

  recRef = React.createRef();

  static propTypes = {
    additionalClasses: PropTypes.string,
    elementId: PropTypes.string.isRequired,
    isBetterNewsCommerce: PropTypes.bool,
    isNBCNewsCommerce: PropTypes.bool,
    isRightRail: PropTypes.bool,
    isTrending: PropTypes.bool,
    isValidNonEcommerceTodayArticle: PropTypes.bool,
    items: PropTypes.arrayOf(relatedPropType).isRequired,
    linkTracking: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    childOfRecommended: PropTypes.bool,
    pageRegion: pageRegionPropType,
  };

  static defaultProps = {
    additionalClasses: '',
    isBetterNewsCommerce: false,
    isNBCNewsCommerce: false,
    isRightRail: false,
    isTrending: false,
    isValidNonEcommerceTodayArticle: false,
    childOfRecommended: false,
    pageRegion: 'article-body',
  };

  componentDidMount() {
    const { isValidNonEcommerceTodayArticle } = this.props;

    if (isValidNonEcommerceTodayArticle) {
      return;
    }

    $t('register', 'mbt_mynews_related_inview');
  }

  onView = (e) => {
    const { childOfRecommended } = this.props;
    if (e?.[0]?.isIntersecting && !childOfRecommended) {
      $t('track', 'mbt_mynews_related_inview', {
        placement: 'body/right-rail',
      });
    }
  };

  render() {
    const {
      additionalClasses,
      childOfRecommended,
      elementId,
      isBetterNewsCommerce,
      isRightRail,
      isTrending,
      isNBCNewsCommerce,
      items,
      linkTracking,
      pageRegion,
      title,
    } = this.props;

    const singleItem = items.length === 1;
    const isNotRightRail = !isRightRail;

    const dataActivityMapID = getDataActivityMapID(
      {
        componentName: 'related',
        pageRegion,
        componentTitle: title,
      },
    );

    return (
      <IntObserver
        threshold={0}
        callback={this.onView}
      >
        <section
          className={classNames(
            'related',
            {
              'related--trending': isTrending || isNBCNewsCommerce,
              'related--single': singleItem && isNotRightRail,
            },
            additionalClasses,
            'dn-print',
          )}
          data-activity-map={!childOfRecommended ? dataActivityMapID : null}
          data-test="related"
          data-testid="related"
          aria-label="Related content"
          ref={this.recRef}
        >
          <h3
            className={classNames({
              related__title: true,
              'related__title--trending': isTrending,
              'related__title--nbcnews-commerce': isNBCNewsCommerce,
              'related__title--betternews-commerce': isBetterNewsCommerce,
            })}
          >
            {title}
          </h3>
          <div
            id={elementId}
            className={classNames(
              'related__items',
              {
                'related__items--vertical': ((isTrending || isNBCNewsCommerce) && isRightRail),
                'related__items--nbcnews-commerce': isNBCNewsCommerce,
                'related__items--betternews-commerce': isBetterNewsCommerce,
              },
            )}
          >
            {items && items.map((item) => (
              <RelatedItem
                key={item.title}
                item={item}
                linkTracking={linkTracking}
                singleItem={singleItem && isNotRightRail}
                isTrending={isTrending}
                isNBCNewsCommerce={isNBCNewsCommerce}
              />
            ))}
          </div>
        </section>
      </IntObserver>
    );
  }
}

export default Related;
