import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import loadScript from 'lib/loadScript';

import styles from './styles.module.scss';

const mapStateToProps = ({ router }) => ({
  domain: router.domain,
});

class NewsvinePoll extends React.Component {
  static propTypes = {
    baseline: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
  }

  async componentDidMount() {
    await loadScript('//code.jquery.com/jquery-2.2.4.min.js');

    // Jenga bootstrap
    const { domain } = this.props;
    await loadScript(`//nvcdn.${domain || 'nbcnews.com'}/_elliott/jenga?widgets=newsletter`);

    const loader = new window.$j.ModuleLoader('polls');
    loader.loadCSS = false;
  }

  shouldComponentUpdate() {
    return false;
  }

  pollClick = (e) => {
    if (e.target && e.target.tagName === 'INPUT') {
      const c = 'checked';
      const t = e.target;
      // Remove checked class.
      const l = t.parentNode.parentNode.querySelectorAll('label');
      for (let n = 0; n < l.length; n += 1) {
        if (l[n].classList) {
          l[n].classList.remove(c);
        } else {
          l[n].className = l[n].className.replace(c, '');
        }
      }
      // Add Checked class.
      t.parentNode.className += ` ${c}`;
    }
  }

  render() {
    const { baseline } = this.props;

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className={styles.container}>
        <div
          className={styles.pollWrapper}
          dangerouslySetInnerHTML={{ __html: baseline }}
          onClick={(e) => this.pollClick(e)}
        />
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}

export default connect(mapStateToProps)(NewsvinePoll);

