import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { related as relatedPropType } from 'lib/CustomPropTypes';
import { generateElementKey } from 'lib/stringUtils';

import Link from 'components/Link';
import Thumbnail from 'components/Thumbnail';
import { Unibrow } from 'components/Unibrow';
import { Save } from 'components/SocialShareMenu/Save';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { RECIRC_SAVE_BUTTON } from 'lib/brandFeatures';
import { useVertical } from 'lib/Hooks';

import styles from './styles.module.scss';

const LINK_TRACKING = 'recommended_giftguide';

// Using sizes with 2x counterparts for Retina screens
const IMAGE_SIZES = {
  s: AIMS_FLAVORS.FOCAL_100x100,
  l: AIMS_FLAVORS.FOCAL_147x147,
};

const GiftGuide = ({ items }) => {
  const { t } = useTranslation();
  const { vertical } = useVertical();
  if (!items || !items.length) {
    return null;
  }

  const hasSaveButton = getFeatureConfigForBrand(
    RECIRC_SAVE_BUTTON,
    vertical,
  );

  return (
    <section className={styles.ggWrapper} data-testid="gift-guide-wrapper">
      <div className={styles.ggInner}>
        <h2 className={styles.title}>
          {t('Gift Guides')}
        </h2>
        <div className={styles.items}>
          {items && items.slice(0, 4).map((item) => {
            const {
              label,
              thumbnail,
              title,
              unibrow,
              url,
              id,
              type,
            } = item;

            return (
              <div className={styles.item} key={generateElementKey(title)} data-testid="gift-guide-item">
                <div className={styles.imageContainer}>
                  <Link
                    href={url}
                    className={styles.itemThumbnail}
                    icid={LINK_TRACKING}
                    data-testid="gift-guide-image"
                  >
                    <Thumbnail
                      label={label}
                      originalHeight={100}
                      originalWidth={100}
                      responsiveFlavors={IMAGE_SIZES}
                      thumbnail={thumbnail}
                    />
                  </Link>
                  {hasSaveButton ? (
                    <Save
                      contentId={id}
                      contentType={type}
                      options={{ isThumbnail: true, offset: 's', showCTA: true }}
                    />
                  ) : null}
                </div>
                <Unibrow unibrow={unibrow} hasDefaultTextStyle />
                <h3
                  className={styles.itemTitle}
                  data-testid="gift-guide-item-title"
                >
                  <Link
                    href={url}
                    icid={LINK_TRACKING}
                  >
                    {title}
                  </Link>
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

GiftGuide.propTypes = {
  items: PropTypes.arrayOf(relatedPropType),
};

GiftGuide.defaultProps = {
  items: [],
};

export { GiftGuide };
