import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconCaret } from 'components/Icon/Caret';

const className = 'faq-card';

function FAQCard(
  props,
) {
  const {
    faq: {
      question,
      answer: {
        html,
      },
    },
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [answerHeight, setAnswerHeight] = useState(null);
  const answerRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
    const answerDiv = answerRef.current.children[0].clientHeight;
    if (answerHeight) {
      setAnswerHeight(null);
    } else {
      // get margin top of first answer element to add to the overall div height for animation
      const answerDivFirstEl = answerRef.current.children[0].children[0];
      const answerDivFirstElMarginTop = parseFloat(window.getComputedStyle(answerDivFirstEl)?.getPropertyValue('margin-top'));
      const calculatedAnswerHeight = (answerDiv + answerDivFirstElMarginTop) || 1;
      setAnswerHeight(calculatedAnswerHeight);
    }
  };

  return (
    <div className={`${className}__card`} data-testid={`${className}__card`}>
      <div
        className={classNames(
          `${className}__content`,
          { 'faq--active': isOpen },
        )}
      >
        <button
          className={`${className}__question`}
          onClick={handleClick}
          type="button"
          data-testid={`${className}__question`}
        >
          <div>
            {question}
          </div>
          <div>
            <IconCaret />
          </div>
        </button>
        <div
          ref={answerRef}
          className={`${className}__answer`}
          style={{ maxHeight: answerHeight }}
          data-testid={`${className}__answer`}
        >
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </div>
  );
}

FAQCard.propTypes = {
  faq: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.shape({
      html: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export { FAQCard };
