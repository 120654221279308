import React from 'react';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';

export class Vizor360Embed extends React.Component {
  static propTypes = {
    widget: CustomPropTypes.widget.isRequired,
  };

  componentDidMount() {
    loadScript('https://vizor.io/scripts/embed.js');
  }

  render() {
    const { widget } = this.props;
    const vizor360EmbedHtml = htmlSetIframeLoadingAttributes(widget.baseline, 'lazy');

    return (
      /* eslint-disable react/no-danger */
      <div
        className="embed-widget__use-presentation"
        dangerouslySetInnerHTML={{ __html: vizor360EmbedHtml }}
      />
      /* eslint-enable react/no-danger */
    );
  }
}
