import React from 'react';
import get from 'lodash.get';
import classNames from 'classnames';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getPackagesByType } from 'lib/curationUtils';
import { ArticleContext } from 'lib/ContextTypes';
import { getUniformTeaseItem } from 'lib/teaseUtils';
import { extractTermPaths, TAX_CONSTANTS } from 'lib/taxonomy';

import { Border } from 'components/MsnbcDaily/Border';
import { useFetch } from 'components/MsnbcDaily/Recirc/fetch';
import { Item } from 'components/MsnbcDaily/Recirc/Body/Item';
import { Picture } from 'components/Picture';

import { getDataActivityMapID } from 'lib/articleUtils';
import { passTrackingMSNBCDaily } from '../../utility';

import styles from './styles.module.scss';

const section = null;
const packageType = 'msnbcDailyArticles';


function RecircBody() {
  const article = React.useContext(ArticleContext);
  const termPaths = extractTermPaths(article.taxonomy);

  const items = [];
  let author;
  let hasColumnist = false;

  // Recirculate author content if is columnist treatement
  if (termPaths.includes(TAX_CONSTANTS.MSNBC.SECTION_COLUMNIST)) {
    // Get first author with page
    author = get(article, 'authors', [])
      .find((auth) => typeof auth.person.url !== 'undefined');
    // Get author's content
    const allItems = get(author, ['person', 'content', 'items'], []);
    for (let i = 0; i < allItems.length; i += 1) {
      // Not currently viewing
      if (article.id !== allItems[i].id) {
        items.push(getUniformTeaseItem(allItems[i]));
      }
      // Use first two matching items
      if (items.length >= 2) break;
    }
    if (items.length) hasColumnist = true;
  }

  // Recirculate MSNBC Daily articles if items array is empty
  if (!items.length) {
    const [response, loading, hasError] = useFetch(
      `curation/slug/msnbc${section ? `/${section}` : ''}`,
    );
    if (loading === true || hasError === true || !response) {
      return null;
    }

    const curation = get(response, ['curation'], {});
    const packages = getPackagesByType(curation, packageType);
    if (!Array.isArray(packages) || !packages.length) {
      return null;
    }

    const daily = packages[0];
    const showFeature = get(daily, ['metadata', 'showFeature'], true);
    const showFeatureNumbered = get(daily, ['metadata', 'showFeatureNumbered'], false);

    const allItems = get(daily, 'items', []);
    for (let i = 0; i < allItems.length; i += 1) {
      const isReading = article.id === allItems[i].id;
      // First story is not featured
      if (i === 0 && !isReading && (!showFeature || (showFeature && showFeatureNumbered))) {
        items.push(getUniformTeaseItem(allItems[i]));
      }
      // Not currently reading
      if (i > 0 && !isReading) {
        items.push(getUniformTeaseItem(allItems[i]));
      }
      // Use first two matching items
      if (items.length >= 2) break;
    }
  }

  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'msnbc-daily-recirc',
      pageRegion: 'article-body',
      componentTitle: 'MSNBC Daily',
    },
  );
  return (
    <div
      className={classNames(
        styles.msnbcDailyRecircBody,
        {
          [styles.hasItems]: items.length > 0,
          [styles.hasColumnist]: hasColumnist,
        },
      )}
      data-activity-map={dataActivityMapID}
    >
      <Border />
      <div className={styles.head}>
        {hasColumnist ? (
          <>
            {get(author, ['person', 'primaryImage', 'url', 'primary'], false) && (
              <div className={styles.headshot}>
                <Picture
                  responsiveFlavors={{
                    s: AIMS_FLAVORS.FOCAL_60x60,
                  }}
                  retina
                  url={get(author, ['person', 'primaryImage', 'url', 'primary'])}
                />
              </div>
            )}
            <h3 className={styles.title}>
              More from&nbsp;
              {get(author, ['person', 'name'], 'this author')}
            </h3>
          </>
        ) : (
          <>
            <h3 className={styles.title}>
              More from MSNBC Daily
            </h3>
            <p className={styles.description}>
              Must reads from Today&#39;s list
            </p>
          </>
        )}
      </div>
      <div className={styles.body}>
        {items.map((item) => {
          // Append tracking to content and unibrow links
          const trackingType = hasColumnist ? 'recircColumnist' : 'recircArticleBody';
          const url = passTrackingMSNBCDaily(get(item, 'url'), trackingType);
          const unibrow = get(item, 'unibrow');

          return (
            <div className={styles.item} key={item.id}>
              <Item
                authors={item.authors}
                headline={item.headline}
                publicationName={get(item, ['metadata', 'publicationName'], null)}
                tease={item.tease}
                type={item.type}
                unibrow={unibrow}
                url={url?.url}
                icid={url?.icid}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { RecircBody };
