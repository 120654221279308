import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import {
  url as urlPropType,
  widget as widgetPropType,
  presentation as presentationPropType,
} from 'lib/CustomPropTypes';
import { decodeString } from 'lib/stringUtils';
import { isShellArticle as isShell } from 'lib/article';

import * as widgetTypes from './widgetTypes';

import './styles.themed.scss';

const WIDGET_SIZES = {
  SMALL: 'Small',
  EDGETOEDGE: 'edgetoedge',
};

const hasMenuEmbed = (properties) => {
  const decodedHtml = decodeString(get(properties, '[\'embed-code\']', '')) || '';
  return !!decodedHtml.match(/class=["“”]menu-embed["“”]/);
};

const mapStateToProps = ({ article, shared, router }) => ({
  url: article.content?.[0]?.url,
  vertical: shared.vertical,
  domain: router.domain,
  isShellArticle: isShell(article),
});

@connect(mapStateToProps)
class EmbedWidget extends Component {
  static propTypes = {
    ...widgetPropType.isRequired,
    url: urlPropType.isRequired, // eslint-disable-line react/no-unused-prop-types
    presentation: presentationPropType.isRequired,
    domain: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    insertEndmark: PropTypes.bool,
    isShellArticle: PropTypes.bool,
  }

  static defaultProps = {
    insertEndmark: false,
    isShellArticle: false,
  }

  render() {
    const {
      presentation: {
        size,
      },
      widget: {
        baseline: html,
        name,
        properties = {},
        publisher: {
          name: vertical,
        },
      } = {},
      insertEndmark,
      isShellArticle,
    } = this.props;

    let getWidgetType = widgetTypes[name];

    if (!getWidgetType) {
      if (html && vertical === 'today') {
        getWidgetType = widgetTypes.todayLegacyWidget;
      } else if (properties?.embed?.type === 'ALL_PURPOSE_EMBED') {
        getWidgetType = widgetTypes.advanced_embed;
      } else {
        return null;
      }
    }

    // embedWidget class is targeted within shorthand articles
    const containerClasses = classNames('embed-widget', 'embedWidget', {
      'embed-widget__small-widget': size === WIDGET_SIZES.SMALL,
      'embed-widget__fb-post': name === 'facebookplus_embedded_post',
      'embed-widget__twitter': name === 'tweet_embed' && size === WIDGET_SIZES.SMALL,
      'embed-widget__youtube': name === 'youtubeplus',
      'embed-widget__instatag-small': name === 'instagramplus' && size === WIDGET_SIZES.SMALL,
      'embed-widget__instagram': name === 'instagramplus' && size !== WIDGET_SIZES.SMALL,
      'embed-widget__opinary': name === 'opinary',
      'embed-widget__vimeo': name === 'vimeo',
      'embed-widget__pinterest': /pinterest/.test(name),
      'embedded-endmark': insertEndmark,
      'embed-widget--shell-article': isShellArticle,
    }, `embed-widget--${size}`);

    let widgetContainsMenuEmbed = false;

    if (name === 'advanced_embed' && html && hasMenuEmbed(properties)) {
      widgetContainsMenuEmbed = true;
    }

    // menu embed widget shouldn't have normal widget classes
    const widgetClassName = widgetContainsMenuEmbed
      ? 'embed-widget__menu'
      : containerClasses;

    return (
      <section className={widgetClassName} data-test="embed-widget" data-testid="embed-widget">
        {getWidgetType(this.props)}
      </section>
    );
  }
}

export default EmbedWidget;
