import React from 'react';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';

export class GraphiqEmbed extends React.Component {
  static propTypes = {
    widget: CustomPropTypes.widget.isRequired,
  };

  componentDidMount() {
    loadScript('https://s.graphiq.com/rx/widgets.js');
  }

  render() {
    const { widget } = this.props;

    return (
      /* eslint-disable react/no-danger */
      <div
        className="embed-widget__use-presentation"
        dangerouslySetInnerHTML={{
          __html: htmlSetIframeLoadingAttributes(widget.baseline, 'lazy'),
        }}
      />
      /* eslint-enable react/no-danger */
    );
  }
}
