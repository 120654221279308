import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Caption from 'components/Caption';
import { Picture } from 'components/Picture';

import { image as imagePropType } from 'lib/CustomPropTypes';
import { imageAltForImage } from 'lib/imageAlt';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import styles from './styles.module.scss';

const sizes = {
  portrait: {
    small: {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_560w,
      l: AIMS_FLAVORS.FIT_320w,
      xl: AIMS_FLAVORS.FIT_360w,
    },
    medium: {
      s: AIMS_FLAVORS.FIT_760w,
      l: AIMS_FLAVORS.FIT_560w,
    },
    large: {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_560w,
      l: AIMS_FLAVORS.FIT_320w,
      xl: AIMS_FLAVORS.FIT_678w,
    },
  },
  landscape: {
    small: {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_560w,
      l: AIMS_FLAVORS.FIT_320w,
      xl: AIMS_FLAVORS.FIT_360w,
    },
    medium: {
      s: AIMS_FLAVORS.FIT_760w,
      l: AIMS_FLAVORS.FIT_560w,
    },
    large: {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_1000w,
      xl: AIMS_FLAVORS.FIT_860w,
    },
    fullBleed: {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_1000w,
      xl: AIMS_FLAVORS.FIT_2000w,
    },
  },
};

function isPortrait(image) {
  return image.height > image.width;
}

function isFullBleed(presentation) {
  return presentation === 'FULL_BLEED';
}

function isSmall(presentation) {
  return presentation === 'small' || presentation === 'halfsize';
}

function isMedium(presentation) {
  return presentation === 'medium' || presentation === 'normal' || presentation === null;
}

function isLarge(presentation) {
  return !isSmall(presentation) && !isMedium(presentation) && !isFullBleed(presentation);
}

function getHeight(orientation, presentation) {
  let dimensions = sizes[orientation];
  if (isSmall(presentation)) {
    dimensions = dimensions.small;
  } else if (isMedium(presentation)) {
    dimensions = dimensions.medium;
  } else if (isFullBleed(presentation)) {
    dimensions = dimensions.fullBleed;
  } else {
    dimensions = dimensions.large;
  }
  return dimensions;
}

function pictureSizes(image, presentation) {
  const orientation = isPortrait(image) ? 'portrait' : 'landscape';
  return getHeight(orientation, presentation);
}

function InlineImage(props) {
  const {
    content: {
      image,
      image: {
        caption,
      },
    },
    presentation,
    lazyloaded,
    elementId,
  } = props;

  const classes = classNames(
    {
      [styles.fullBleedContainer]: isFullBleed(presentation),
      [styles.inlineImage]: !isFullBleed(presentation),
      [styles.large]: isLarge(presentation),
      [styles.medium]: isMedium(presentation),
      [styles.small]: isSmall(presentation),
      [styles.portrait]: isPortrait(image),
      [styles.noCaption]: typeof caption !== 'string' || caption.length <= 0,
    },
  );

  const { height, width } = image;

  return (
    <figure className={classes} id={elementId ? `anchor-${elementId}` : undefined}>
      <Picture
        alt={imageAltForImage(image)}
        className={classNames(
          styles.image,
          {
            [styles.fullBleed]: isFullBleed(presentation),
          },
        )}
        flavor="fit"
        originalHeight={height}
        originalWidth={width}
        responsiveFlavors={pictureSizes(image, presentation)}
        retina
        url={image.url.primary}
        lazyloaded={lazyloaded}
      />
      <Caption
        className={styles.caption}
        image={image}
      />
    </figure>
  );
}

InlineImage.propTypes = {
  content: imagePropType.isRequired,
  presentation: PropTypes.string.isRequired,
  lazyloaded: PropTypes.bool,
  elementId: PropTypes.string,
};
InlineImage.defaultProps = {
  lazyloaded: true,
  elementId: '',
};
export { InlineImage };
