import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PLACEHOLDER_REGEX } from 'lib/buildAffiliateLink';
import ProductPropType from 'lib/CustomPropTypes/product';
import { LazyBacon } from 'components/packages/Bacon';
import { useVertical } from 'lib/Hooks';
import { useInView } from 'react-intersection-observer';
import { stub as $t } from '@nbcnews/analytics-framework';

$t('register', 'mbt_curatedlist_inview');

function updateParam(url, param, value) {
  try {
    const urlObj = new URL(decodeURIComponent(url));
    urlObj.searchParams.set(param, value);
    return urlObj.toString();
  } catch (e) {
    return url;
  }
}

export function CuratedList(props) {
  const {
    id,
    articleId,
    items,
    title,
    description,
    ecommerceEnabled,
    curatedListTrackingId,
    articleTrackingId,
    disclaimerOverride,
    insertEndmark,
    className,
    shortDisclaimer,
  } = props;

  const { vertical } = useVertical();

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) $t('track', 'mbt_curatedlist_inview', { id });
  }, [inView]);

  if (!items) return null;

  // The trackingId comes from Bento API with the Curated List ID appended to it.
  // So, here we replace the Curated List ID with the Article ID.
  const sanitizedCuratedListTrackingId = (id && curatedListTrackingId?.includes(id))
    ? curatedListTrackingId.replace(id, '')
    : curatedListTrackingId || '';

  const getOfferUrl = (offer) => {
    let offerUrl = offer.externalUrl || '';

    const completeTrackingId = curatedListTrackingId
      ? `${sanitizedCuratedListTrackingId}${articleTrackingId}`
      : articleTrackingId;

    // The URL sometimes comes from Bento API with multiple SREF keys.
    // Here, we remove all SREF keys and values, then append a new SREF with
    // a computed value.
    if (offerUrl.includes('go.redirectingat')) {
      const path = vertical === 'today' ? 'shop' : 'select';
      const domain = vertical === 'select' ? 'nbcnews' : vertical;
      const skimLinkSref = `https://www.${domain}.com/${path}/${completeTrackingId}`;
      offerUrl = updateParam(offerUrl, 'sref', skimLinkSref);
    }

    if (!articleId) return offerUrl;

    // Replace the Affiliate Link placeholder text
    if (PLACEHOLDER_REGEX.test(offerUrl)) {
      offerUrl = offerUrl.replace(PLACEHOLDER_REGEX, completeTrackingId);
      offerUrl = offerUrl.replace(id, '');
    }
    // Replace the Curated List ID with the Article ID in any remaining instances
    // within the URL
    if (id && offerUrl.includes(id)) {
      offerUrl = offerUrl.replace(curatedListTrackingId, completeTrackingId);
    }
    return offerUrl;
  };

  return (
    <div ref={ref} data-testid="curated-list" className={classnames('no-slanted-bg', className, { endmark: insertEndmark })}>
      <LazyBacon
        vertical={vertical}
        shouldShowAdditionalSeller
        inlineArticle
        content={{
          type: 'oneOneProduct',
          subType: 'oneOneProduct',
          metadata: {
            description,
            disclaimerKey: 'OVERRIDE',
            disclaimerOverride,
            ecommerceEnabled,
            title,
            componentTitle: 'curated-list',
            trackingId: sanitizedCuratedListTrackingId,
            shortDisclaimer,
            hideDisclaimerLearnMore: true,
          },
          items: items
            .filter((item) => item.type === 'product')
            .map((item) => ({
              type: item.type,
              id: item.id,
              item: {
                ...item,
                offers: item.offers.map((offer) => ({
                  ...offer,
                  externalUrl: getOfferUrl(offer),
                })),
              },
              computedValues: {
                headline: item.name,
                teaseImage: item.teaseImage,
                url: null,
              },
            })),
        }}
        useAltTitle={vertical === 'today'}
        titleBgColor="yellow"
      />
    </div>
  );
}

CuratedList.propTypes = {
  id: PropTypes.string.isRequired,
  articleId: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  curatedListTrackingId: PropTypes.string.isRequired,
  articleTrackingId: PropTypes.string.isRequired,
  disclaimerOverride: PropTypes.string,
  items: PropTypes.arrayOf(ProductPropType).isRequired,
  insertEndmark: PropTypes.bool,
  className: PropTypes.string,
  ecommerceEnabled: PropTypes.bool,
  shortDisclaimer: PropTypes.string,
};

CuratedList.defaultProps = {
  disclaimerOverride: null,
  articleId: null,
  insertEndmark: false,
  className: '',
  ecommerceEnabled: false,
  shortDisclaimer: null,
};

