import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  presentation as presentationPropType,
  widget as widgetPropType,
} from 'lib/CustomPropTypes';

import loadScript from 'lib/loadScript';

export class FacebookEmbed extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    presentation: presentationPropType.isRequired,
    widget: widgetPropType.isRequired,
    facebookPostType: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    facebookPostType: 'post',
  };

  componentDidMount() {
    loadScript('https://connect.facebook.net/en_US/sdk.js#xfbml=false&version=v2.10')
      // re-initing here since in SPA mode, the script needs to re-parse
      // embeds on the page when navigating to a new article
      .thenOnFirstLoad(() => window.FB.init({ version: 'v2.10', xfbml: true }))
      .then(() => window.FB.XFBML.parse());
  }

  getPostUrl() {
    const { widget } = this.props;

    return widget.properties['facebookplus-post-url']
      || widget.properties['video-url'];
  }

  wrapped() {
    const { presentation: { size } } = this.props;
    return (
      <div
        className="fb-video"
        data-href={this.getPostUrl()}
        data-show-text="true"
        width={size.toLowerCase() === 'small' ? 360 : null}
        ref={(div) => { this.containerDiv = div; }}
      />
    );
  }

  render() {
    const { className, facebookPostType, widget } = this.props;

    if (
      facebookPostType === 'video'
      || widget.properties['facebookplus-post-url']?.includes('watch')
      || widget.properties['facebookplus-post-url']?.includes('video')
    ) {
      return this.wrapped();
    }

    const classes = classNames({
      'embed-widget__use-presentation': true,
      [className]: className,
      'fb-post': facebookPostType === 'post',
    });

    return (
      <div
        className={classes}
        data-href={this.getPostUrl()}
        data-show-text="true"
        data-width="auto"
        ref={(div) => { this.containerDiv = div; }}
        data-testid="facebook-embed"
      />
    );
  }
}
