// google recaptcha v2 invisible type
// administered by NOS under https://www.google.com/recaptcha/admin/
export const pickSiteKey = (vertical) => {
  switch (vertical) {
    case 'news':
      return '6LeCn4odAAAAAFt1umE7XdVqlpUB2-e20vksHlQL';
    case 'today':
      return '6LeCn4odAAAAAFt1umE7XdVqlpUB2-e20vksHlQL';
    case 'msnbc':
      return '6LeCn4odAAAAAFt1umE7XdVqlpUB2-e20vksHlQL';
    case 'noticias':
      return '6LfHVOQUAAAAAPSCmupFMjHMATCo3lMVB9f6LFdK';
    default:
      return '6LeCn4odAAAAAFt1umE7XdVqlpUB2-e20vksHlQL'; // news
  }
};
