import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as CustomPropTypes from 'lib/CustomPropTypes';

import loadScript from 'lib/loadScript';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';

export class InstagramEmbed extends React.Component {
  static propTypes = {
    html: PropTypes.string,
    className: PropTypes.string,
    widget: CustomPropTypes.widget.isRequired,
  };

  static defaultProps = {
    html: null,
    className: '',
  };

  componentDidMount() {
    loadScript('//www.instagram.com/embed.js')
      // re-running `.process` here since in SPA mode, the script needs to re-parse
      // embeds on the page when navigating to a new article
      .then(() => window.instgrm.Embeds.process());
  }

  render() {
    const { html, className, widget } = this.props;
    const innerHTML = htmlSetIframeLoadingAttributes((html || widget.baseline).trim(), 'lazy');
    const wrapperClass = classNames(className, 'embed-widget__use-presentation');

    // Replace iframe embed with blockquote markup
    if (/^<iframe/.test(innerHTML)) {
      const embedId = `https://www.instagram.com/p/${widget.properties['instagram-id']}`;
      return (
        <div className={wrapperClass}>
          <blockquote
            className="instagram-media embed-widget__blockquote"
            data-instgrm-captioned
            data-instgrm-permalink={embedId}
            data-instgrm-version="8"
          >
            <div className="embed-widget__outer">
              <div className="embed-widget__inner">
                <div className="embed-widget__profile" />
              </div>
              <p className="embed-widget__paragraph">
                <a href={embedId}>{embedId}</a>
              </p>
            </div>
          </blockquote>
        </div>
      );
    }

    // eslint-disable-next-line react/no-danger
    return <div className={wrapperClass} dangerouslySetInnerHTML={{ __html: innerHTML }} />;
  }
}
