import React from 'react';
import { widget as widgetPropType } from 'lib/CustomPropTypes';

import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';
import { TweetEmbed } from './widgets/TweetEmbed';
import { StorifyWidget } from './widgets/StorifyWidget';
import { InstagramEmbed } from './widgets/InstagramEmbed';
import { FacebookEmbed } from './widgets/FacebookEmbed';
import { AdvancedEmbed } from './widgets/AdvancedEmbed';
import { QzzrEmbed } from './widgets/QzzrEmbed';
import { NbcAffiliatesVideoLegacy } from './widgets/NbcAffiliatesVideoLegacy';
import { GraphiqEmbed } from './widgets/GraphiqEmbed';
import { Vizor360Embed } from './widgets/Vizor360Embed';
import { Pinterest } from './widgets/Pinterest';
import { SponsoredTip } from './widgets/SponsoredTip';
import { IframelyEmbed } from './widgets/IframelyEmbed';

const genericDiv = (className) => {
  const div = ({ widget: { baseline } }) => (
    // not replacing http urls with https here because some 3rd parties don't support https
    // for their iframe urls
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: htmlSetIframeLoadingAttributes(baseline, 'lazy'),
      }}
    />
  );
  div.propTypes = {
    widget: widgetPropType.isRequired,
  };
  return div;
};

/* eslint-disable camelcase */

const qzzr = (props) => <QzzrEmbed {...props} className="embed-widget__quiz-wrapper" />;

const qzzr_2017 = (props) => <QzzrEmbed {...props} className="embed-widget__quiz-wrapper" />;

const brightkit = genericDiv('embed-widget__hoot-suite-wrapper');

const youtubeplus = ({ widget: { properties: { 'youtube-id': youtubeId } } }) => (
  <iframe
    loading="lazy"
    title="youtube embed"
    className="embed-widget__use-presentation"
    src={`https://www.youtube.com/embed/${youtubeId}`}
    frameBorder="0"
    allowFullScreen
  />
);

youtubeplus.propTypes = {
  widget: widgetPropType.isRequired,
};

const tweetplus_embed = (props) => <TweetEmbed {...props} />;
const tweet_embed = (props) => <TweetEmbed {...props} />;

const facebookplus_embedded_post = (props) => <FacebookEmbed {...props} facebookPostType="post" />;
const facebook_embedded_post = (props) => <FacebookEmbed {...props} facebookPostType="post" />;

const facebook_video = (props) => <FacebookEmbed {...props} facebookPostType="video" />;

const facebook_video_live_vertical = (props) => (
  <FacebookEmbed {...props} facebookPostType="video" />
);

const facebookvideo = () => (
  <iframe
    loading="lazy"
    title="facebook video"
    className="embed-widget__fb-video"
    // eslint-disable-next-line max-len
    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FNBCNews%2Fvideos%2F1756173594402663%2F&show_text=0&width=545"
    width="545"
    height="535"
    style={{
      border: 'none',
      overflow: 'hidden',
    }}
    scrolling="no"
    frameBorder="0"
    allowTransparency="true"
    allowFullScreen="true"
  />
);

const facebookvideosmall = () => (
  <iframe
    loading="lazy"
    title="facebook video"
    className="embed-widget__fb-video-small"
    // eslint-disable-next-line max-len
    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FNBCNews%2Fvideos%2F1756173594402663%2F&show_text=0&width=368"
    width="368"
    height="300"
    style={{
      border: 'none',
      overflow: 'hidden',
    }}
    scrolling="no"
    frameBorder="0"
    allowTransparency="true"
    allowFullScreen="true"
  />
);

const instagramplus = (props) => <InstagramEmbed {...props} />;
const instagram = (props) => <InstagramEmbed {...props} />;

const opinary = ({ widget: { fallbackUrl: url } }) => (
  <div className="embed-widget__opinary-widget-wrapper">
    <div className="embed-widget__opinary-widget">
      <iframe
        loading="lazy"
        title="opinary embed"
        className="opinary-iframe"
        width="100%"
        height="400px"
        src={url}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
      />
    </div>
    <script src="//compass.pressekompass.net/static/opinary.js" />
  </div>
);

opinary.propTypes = {
  widget: widgetPropType.isRequired,
};

const msnbc_video = genericDiv();

const cnbc_video = genericDiv();

const storify = (props) => <StorifyWidget {...props} />;

const graphiq = (props) => <GraphiqEmbed {...props} />;

const vimeo = genericDiv();

const vine = genericDiv();

const advanced_embed = (props) => <AdvancedEmbed {...props} />;

const todayLegacyWidget = genericDiv();

const nbc_affiliates_video = (props) => <NbcAffiliatesVideoLegacy {...props} />;

const vizor360 = (props) => <Vizor360Embed {...props} />;

const pinterest_pin = (props) => <Pinterest {...props} />;

const pinterest_board = (props) => <Pinterest {...props} />;

const sponsored_tip = (props) => <SponsoredTip {...props} />;

const IFRAMELY_EXTERNAL_EMBED = (props) => <IframelyEmbed {...props} />;

export {
  qzzr,
  qzzr_2017,
  brightkit,
  youtubeplus,
  tweetplus_embed,
  tweet_embed,
  facebookplus_embedded_post,
  facebook_embedded_post,
  facebook_video,
  facebook_video_live_vertical,
  facebookvideo,
  facebookvideosmall,
  IFRAMELY_EXTERNAL_EMBED,
  instagramplus,
  instagram,
  opinary,
  msnbc_video,
  cnbc_video,
  storify,
  graphiq,
  vimeo,
  vine,
  advanced_embed,
  todayLegacyWidget,
  nbc_affiliates_video,
  vizor360,
  pinterest_pin,
  pinterest_board,
  sponsored_tip,
};
