/**
 * Init for non-prod Sailthru account
 * @param {string} customerId Sailthru id
 */
export const init = async (customerId) => {
  try {
    await window.Sailthru.init({ customerId });
  } catch (e) {
    console.error('error initializing non-prod Sailthru client', e);
  }
};

/**
 * MParticle, an analytics library the BI team manages, initializes Sailthru
 * defaulting to our prod instance with customerId of "a839e8824b3cdd864a9ba3052205d70b"
 * @param {object} signupValues https://getstarted.sailthru.com/developers/api-client/javascript/
 * @param {string} customerId Sailthru id
 */
export const sailthruSignUp = async (signupValues, customerId) => {
  if (window.Sailthru && typeof window.Sailthru === 'object') {
    if (customerId) {
      // Must await otherwise Sailthru could be in the middle of initializing when
      // integration userSignUp is sent
      await init(customerId);
    }
    const { id, email } = signupValues;
    if (id && email) {
      window.Sailthru.integration('userSignUp', signupValues);
    }
  }
};
