import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import loadScript from 'lib/loadScript';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';

export class QzzrEmbed extends React.Component {
  static propTypes = {
    html: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    html: '',
    className: '',
  };

  componentDidMount() {
    loadScript('//dcc4iyjchzom0.cloudfront.net/widget/loader.js');
  }

  render() {
    const { html, className } = this.props;
    const qzzrEmbedHtml = htmlSetIframeLoadingAttributes(
      html.replace(/http:\/\//, 'https://'),
      'lazy',
    );

    return (
      /* eslint-disable react/no-danger */
      <div
        className={classNames(className, 'embed-widget__use-presentation')}
        dangerouslySetInnerHTML={{ __html: qzzrEmbedHtml }}
      />
      /* eslint-enable react/no-danger */
    );
  }
}
