import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RouteContext, VerticalContext } from 'lib/ContextTypes';
import styles from './styles.module.scss';

const ExpandableList = (props) => {
  const {
    cards,
    callToActionText,
    trackDataAnalyticsClicks,
    numberOfCardsToShow,
    subtitle,
    title,
  } = props;

  const vertical = React.useContext(VerticalContext);
  const route = React.useContext(RouteContext);
  const isAmp = route.path.indexOf('embedded-pkg') !== -1;

  React.useEffect(() => {
    // Send a window message to the parent, AMP in this case, to aid in resizing the package
    // within the iFrame. I used an asterisk for the target origin because we don't know
    // where the other window's document will be located. This is safe for us because
    // the only data we are sending is the scrollHeight, nothing malicious can be done with this.
    requestAnimationFrame(() => {
      window.parent.postMessage(
        {
          sentinel: 'amp',
          type: 'embed-size',
          height: document.body.scrollHeight > 100 ? `${document.body.scrollHeight}px` : '555px',
        },
        '*',
      );
    });
  });

  const [isExpanded, setIsExpanded] = useState(false);

  if (!cards.length) return null;

  const hasHiddenCards = cards.length > numberOfCardsToShow;
  const numHiddenCards = hasHiddenCards ? cards.length - numberOfCardsToShow : 0;

  const containerRef = useRef();

  const getCardList = () => {
    if (!hasHiddenCards || (hasHiddenCards && isExpanded)) {
      return cards.map((c, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{c}</li>));
    }

    return [
      // eslint-disable-next-line react/no-array-index-key
      ...cards.slice(0, numberOfCardsToShow).map((c, index) => (<li key={index}>{c}</li>)),
      (
        <li
          className={styles.expandableListAffordance}
          data-testid="expandable-list__affordance"
          inert="true"
          key="affordance"
        >
          <div className={styles.expandableListOverlay} />
          {cards[numberOfCardsToShow]}
        </li>
      ),
      // eslint-disable-next-line react/no-array-index-key
      ...cards.slice(numberOfCardsToShow + 1).map((c, index) => (<li key={numberOfCardsToShow + index} style={{ display: 'none' }}>{c}</li>)),
    ];
  };

  const handleExpandListClick = ({ expand, callToAction }) => {
    setIsExpanded(expand);
    trackDataAnalyticsClicks(callToAction);
  };

  return (
    <div
      className={classNames(styles.expandableList, { [styles.expandableListAmp]: isAmp })}
      data-testid="expandable-list"
    >
      <div className={styles.expandableListHeader}>
        <div className={styles.expandableListHeaderTab}>
          {
            vertical === 'select' && (
              <h3 className={styles.expandableListTitle}>
                {title}
                {
                  title && subtitle && (
                    <span
                      className={styles.expandableListSubtitle}
                      data-testid="expandable-list__subtitle"
                    >
                      {subtitle}
                    </span>
                  )
                }
              </h3>
            )
          }
          {
            vertical === 'today' && (
              <>
                <h3 className={styles.expandableListTitle}>
                  {title}
                </h3>
                {
                  title && subtitle && (
                    <h4
                      className={styles.expandableListSubtitle}
                      data-testid="expandable-list__subtitle"
                    >
                      {subtitle}
                    </h4>
                  )
                }
              </>
            )
          }
        </div>
      </div>
      <ul
        ref={containerRef}
        className={classNames(styles.expandableListContainer,
          { [styles.expandableListContainerNoSubtitle]: !subtitle },
          { [styles.expandableListContainerNoExpand]: !hasHiddenCards })}
      >
        {getCardList()}
      </ul>
      {
        hasHiddenCards && !isExpanded && (
          <button
            className={styles.expandableListButton}
            onClick={() => handleExpandListClick({ expand: true, callToAction: 'showmore' })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsExpanded(true);
                setTimeout(() => {
                  containerRef.current
                    .querySelectorAll('li')[numberOfCardsToShow]
                    .querySelector('a')
                    .focus();
                }, 100);
              }
            }}
            type="button"
          >
            {callToActionText?.collapsed || `See ${numHiddenCards} More`}
            <span className={`${styles.icon} ${styles.iconDown} icon icon-chevron-right`} />
          </button>
        )
      }

      {
        hasHiddenCards && isExpanded && (
          <button
            className={styles.expandableListButton}
            onClick={() => handleExpandListClick({ expand: false, callToAction: 'showless' })}
            type="button"
          >
            {callToActionText?.expanded || 'See Less'}
            <span className={`${styles.icon} ${styles.iconUp} icon icon-chevron-right`} />
          </button>
        )
      }
    </div>
  );
};

ExpandableList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.node),
  callToActionText: PropTypes.shape({
    collapsed: PropTypes.string,
    expanded: PropTypes.string,
  }),
  trackDataAnalyticsClicks: PropTypes.func,
  numberOfCardsToShow: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

ExpandableList.defaultProps = {
  cards: [],
  callToActionText: {
    collapsed: '',
    expanded: '',
  },
  trackDataAnalyticsClicks: () => {},
  numberOfCardsToShow: 3,
  subtitle: '',
  title: '',
};

export { ExpandableList };
