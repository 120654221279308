/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import { connect, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { stub as $t } from '@nbcnews/analytics-framework';

import Related from 'components/Related';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { GiftGuide } from 'components/GiftGuide';
import './styles.themed.scss';

import { getDataActivityMapID } from 'lib/articleUtils';
import { pageRegion as pageRegionPropType } from 'lib/CustomPropTypes';
import { VIEW } from 'lib/view';
import { fetchAndSetFormattedItems, shouldShowRelatedContent } from './utils';

$t('register', 'mbt_mynews_recommended_inview');

const mapStateToProps = (state) => {
  const taxonomy = get(state, 'article.content[0].taxonomy');
  const articleID = get(state, 'article.content[0].id');
  return {
    taxonomy,
    articleID,
  };
};

const getTitle = (props) => {
  const {
    isGiftGuide,
    isShopTheShow,
    isTrending,
    isValidNonEcommerceTodayArticle,
  } = props;

  if (isShopTheShow) return i18next.t('Shop the Show');
  if (isTrending) return i18next.t('Trending');
  if (isValidNonEcommerceTodayArticle) return i18next.t('Shop TODAY');
  if (isGiftGuide) return i18next.t('Gift Guides');
  return i18next.t('Recommended');
};

/**
 * Render function that checks props to determine if we need to render a Gift Guide component or a
 * Related component. If no items are provided to render, returns null.
 */
const getRecoComponent = (props, formattedItems) => {
  const {
    additionalClasses,
    currentPath,
    isBetterNewsCommerce,
    isEmbeddedComponent,
    isGiftGuide,
    isNBCNewsCommerce,
    isRightRail,
    isShopTheShow,
    isTrending,
    isValidNonEcommerceTodayArticle,
  } = props;

  // No results, return early
  if (!formattedItems.length) {
    return null;
  }

  const title = getTitle(props);

  const id = title.toLowerCase();

  let linkTrackingID;
  if (isShopTheShow) {
    linkTrackingID = 'ShoptheShow';
  } else if (isTrending || isNBCNewsCommerce) {
    linkTrackingID = 'recommended_shop';
  } else {
    linkTrackingID = id;
  }

  if (isGiftGuide) {
    return (
      <GiftGuide
        items={formattedItems}
        data-test="gift-guide"
        data-testid="gift-guide"
      />
    );
  }

  if (shouldShowRelatedContent(props)) {
    return (
      <Related
        additionalClasses={additionalClasses}
        currentPath={currentPath}
        elementId={id}
        isBetterNewsCommerce={isBetterNewsCommerce}
        isEmbeddedComponent={isEmbeddedComponent}
        isNBCNewsCommerce={isNBCNewsCommerce}
        isRightRail={isRightRail}
        isTrending={isTrending}
        isValidNonEcommerceTodayArticle={isValidNonEcommerceTodayArticle}
        items={formattedItems}
        linkTracking={linkTrackingID}
        title={title}
        childOfRecommended
      />
    );
  }

  return null;
};

export const runAnalytics = (event, callback) => {
  const { boundingClientRect: { height } } = event;
  if (height > 1) {
    callback('track', 'mbt_mynews_recommended_inview', {
      placement: 'body/right-rail',
    });
  }
};

const Recommended = (props) => {
  const {
    isRightRail,
    lazyLoad,
    isGiftGuide,
    isTrending,
    isValidNonEcommerceTodayArticle,
    pageRegion,
  } = props;
  const [formattedItems, setFormattedItems] = useState([]);

  // useInView hook with options that determine when to fetch items
  const [inViewRefFetchItems, inViewFetchItems] = useInView({
    initialInView: !lazyLoad,
    triggerOnce: true,
    // The footer gift guide widget can start loading earlier, since users need to scroll down to it
    rootMargin: isRightRail ? '0px' : '60px 0px',
  });
  useEffect(() => {
    if (inViewFetchItems) {
      fetchAndSetFormattedItems(props, setFormattedItems);
    }
  }, [inViewFetchItems]);

  // useInViewHook with options to track analytics
  const [inViewRefAnalytics, inViewAnalytics, entryAnalytics] = useInView({
    threshold: 0,
  });
  useEffect(() => {
    const isRecommendedBlockType = !isTrending && !isValidNonEcommerceTodayArticle && !isGiftGuide;
    if (inViewAnalytics && isRecommendedBlockType) {
      runAnalytics(entryAnalytics, $t);
    }
  }, [inViewAnalytics, entryAnalytics, isTrending, isValidNonEcommerceTodayArticle, isGiftGuide]);

  // this combines the different refs for the 2 different useInView use cases
  const setRefs = useCallback(
    (node) => {
      inViewRefFetchItems(node);
      inViewRefAnalytics(node);
    },
    [inViewRefFetchItems, inViewRefAnalytics],
  );

  const isStartToday = useSelector(({ shared }) => shared.view);

  if (isStartToday === VIEW.START_TODAY_APP) {
    return null;
  }

  const renderedComponent = getRecoComponent(props, formattedItems);
  const recommendedClassname = classNames(
    'recommended-intersection-ref',
    { 'recommended--sticky': isRightRail && !isValidNonEcommerceTodayArticle },
  );

  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'recommended',
      pageRegion,
      componentTitle: getTitle(props),
    },
  );

  return (
    <div
      className={recommendedClassname}
      data-activity-map={dataActivityMapID}
      data-test="recommended-wrapper"
      data-testid="recommended-wrapper"
      ref={renderedComponent && setRefs}
    >
      {renderedComponent}
    </div>
  );
};

Recommended.propTypes = {
  additionalClasses: PropTypes.string,
  articleID: PropTypes.string,
  isBetterNewsCommerce: PropTypes.bool,
  isEmbeddedComponent: PropTypes.bool,
  isGiftGuide: PropTypes.bool,
  isNBCNewsCommerce: PropTypes.bool,
  isRightRail: PropTypes.bool,
  isTrending: PropTypes.bool,
  isShopTheShow: PropTypes.bool,
  isSelect: PropTypes.bool,
  isValidNonEcommerceTodayArticle: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  mobile: PropTypes.bool,
  pageRegion: pageRegionPropType,
  taxonomy: PropTypes.shape({
    primarySection: PropTypes.shape({
      id: PropTypes.string,
    }),
    primaryTopic: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Recommended.defaultProps = {
  additionalClasses: '',
  articleID: '',
  isBetterNewsCommerce: false,
  isEmbeddedComponent: false,
  isGiftGuide: false,
  isNBCNewsCommerce: false,
  isRightRail: false,
  isTrending: false,
  isSelect: false,
  isValidNonEcommerceTodayArticle: false,
  isShopTheShow: false,
  lazyLoad: false,
  mobile: true,
  pageRegion: 'article-sidebar',
  taxonomy: {},
};

const WrappedRecommended = connect(mapStateToProps)(Recommended);

function SafeRecommended(props) {
  return (
    <ErrorBoundary>
      <WrappedRecommended {...props} />
    </ErrorBoundary>
  );
}

// used for debugging (and also a brittle test)
SafeRecommended.displayName = 'SafeRecommended(Connect(Recommended))';

export default SafeRecommended;
