import React from 'react';
import PropTypes from 'prop-types';
import { FAQCard } from './FAQCard';
import './styles.themed.scss';

const className = 'faq-embed';

function FAQEmbed(
  props,
) {
  const {
    faqs,
    headline,
    id,
  } = props;

  if (!headline || !faqs) {
    return null;
  }

  return (
    <div className={className} data-test="article-faq-embed" data-testid="article-faq-embed" id={`faq-embed-jumplink-${id}`}>
      <div className={`${className}__content`}>
        <h2 className={`${className}__headline`}>
          <span className="text">{headline}</span>
        </h2>
        <div className={`${className}__cards`} data-testid={`${className}__cards`}>
          {
            faqs.map((faq, i) => (
              // data does not contain a unique key so array index needed for key
              // eslint-disable-next-line react/no-array-index-key
              <FAQCard faq={faq} key={`${i}-${faq.question}`} />
            ))
          }
        </div>
      </div>
    </div>
  );
}

FAQEmbed.propTypes = {
  headline: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.shape({
        html: PropTypes.string,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export { FAQEmbed };
