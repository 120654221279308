import React from 'react';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link/LinkIfHref';

import {
  decodeString,
  generateElementKey,
} from 'lib/stringUtils';

import styles from './styles.module.scss';

/**
 * Statement renders a blockquote with text and optional attribution.
 * The text is split into paragraphs and each paragraph is rendered in a <p> tag.
 * The attribution is rendered in a footer with a link if an attributionUrl is provided.
 *
 * @component
 * @param {Object} props - The properties that define the Statement component.
 * @param {string} props.text - The text of the statement. It is required and defaults to an empty string.
 * @param {string} props.attribution - The attribution of the statement. Defaults to null.
 * @param {string} props.attributionUrl - The URL for the attribution. If provided, the attribution will be rendered as a link. Defaults to null.
 *
 * @example
 * <Statement text="This is a statement." attribution="Author Name" attributionUrl="https://example.com" />
 *
 * @returns {React.Element} The rendered Statement component, or null if no attribution is provided.
 */

export function Statement({
  text, attribution, attributionUrl,
}) {
  if (!text) return null;

  return (
    <blockquote
      className={styles.statement}
      cite={attributionUrl}
    >
      {decodeString(text).split(/[\n\r]+/).map((graf) => (
        <p className={styles.statementText} key={generateElementKey(graf)}>
          {graf}
        </p>
      ))}
      {attribution && (
        <footer className={styles.statementFooter}>
          <LinkIfHref
            href={attributionUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {decodeString(attribution)}
          </LinkIfHref>
        </footer>
      )}
    </blockquote>
  );
}

Statement.propTypes = {
  attribution: PropTypes.string,
  attributionUrl: PropTypes.string,
  text: PropTypes.string,
};

Statement.defaultProps = {
  attribution: null,
  attributionUrl: null,
  text: null,
};

