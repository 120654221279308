export const parseIdFromUrl = (url) => {
  if (!url) return null;

  const id = url.split('-').pop();
  const prefix = id[0];
  const postfix = id.substring(1);

  if (prefix === 'n') return `ncna${postfix}`;
  if (prefix === 't') return `tdna${postfix}`;
  if (/\d/.test(prefix)) return `mmvo${id}`;

  return null;
};
