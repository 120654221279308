import tldjs from 'tldjs';
import { verticalToPath } from './vertical';

const DEFAULT_TAG = 'tag=nb013-20';
export const PLACEHOLDER_REGEX = new RegExp('trackingCodePlaceholder', 'i');

export const EXCEPTIONS = [
  'anrdoezrs.net',
  'ashleycarsondesigns.com',
  'birthdatecandles.com',
  'blankboxnyc.com',
  'bookofthemonth.com',
  'boomboxgifts.com',
  'bymarinabulatkina.com',
  'californiaclosets.com',
  'clarasunwoo.com',
  'click.linksynergy.com',
  'colorwowdeals.com',
  'colorwowhair.com',
  'cuddleclones.com',
  'go.redirectingat.com',
  'golfballs.com',
  'hairstory.com',
  'holodeck3dstudios.com',
  'indigolovesviolet.com',
  'jennifermillerjewelry.com',
  'linksynergy.com',
  'linksynergy.walmart.com',
  'loveisproject.co',
  'lunya.co',
  'mace.com',
  'maggielouiseconfections.com',
  'mailpix.com',
  'marlynschiff.com',
  'prodnaskincare.com',
  'schafskincare.com',
  'shanicollection.com',
  'shareasale.com',
  'shhhsilk.com',
  'shop.leota.com',
  'shop-links.co',
  'shopryanporter.com',
  'shrsl.com',
  'ss1.us',
  'tajacollection.com',
  'thecomfy.com',
  'undercovercolors.com',
  'wearcommando.com',
  'zeenwoman.com',
];

const AMAZON = ['amazon.com'];

/*
[wawjr3d][2/25/2018] No longer used but it I don't know why these were listed

const NON_MERCHANTS = [
  'today.com',
  'people.com',
  'yummielife.com',
  'intothegloss.com',
  'pinterest.com',
  'ohhappyday.com',
  'foodnetwork.com',
  'brit.co',
  'lifesambrosia.com',
  'chefanie.com',
];
*/

export const encodeCommerceCurationUrl = ({
  baseUrl,
  vertical = 'today',
  pageRoute = '',
  trackingId = '',
}) => {
  if (!baseUrl) {
    return null;
  }

  if (PLACEHOLDER_REGEX.test(baseUrl)) {
    return baseUrl.replace(PLACEHOLDER_REGEX, trackingId);
  }

  if (baseUrl.includes('go.redirectingat')) {
    const curationUrl = `https://www.${
      verticalToPath[vertical] || `${vertical}.com`
    }/${pageRoute}/${trackingId}`;
    return `${baseUrl}&sref=${encodeURIComponent(curationUrl)}`;
  }

  return baseUrl;
};

const inDomains = (domains, domain) => domains.indexOf(domain) !== -1;

const determineHostname = (link) => tldjs.parse(link).hostname;
const determineDomain = (link) => tldjs.parse(link).domain;

function addTagToAmazonLink({ href, tag, override }) {
  // Note: tag=nb013-20 is the default tag if no other is provided by editorial
  if (!override && href.indexOf('tag=') !== -1) {
    return href;
  }

  const parsedUrl = new URL(href);
  const tagParam = parsedUrl.searchParams.get('tag');
  if (!tagParam || override) {
    if (tag.startsWith('tag=')) {
      parsedUrl.searchParams.set(...tag.split('='));
    } else {
      parsedUrl.searchParams.set('tag', tag);
    }
  }

  return parsedUrl.toString();
}

export default function BuildAffiliateLink({
  url: href,
  canonicalUrl,
  tag = DEFAULT_TAG,
  override = false,
}) {
  const hostname = determineHostname(href);
  const domain = determineDomain(href);

  if (inDomains(EXCEPTIONS, hostname) || inDomains(EXCEPTIONS, domain)) {
    if (href.includes('www.nbcnews.com')) return href.replace('96128X1608529', '96128X1608993');
    return href;
  }

  if (inDomains(AMAZON, domain)) {
    return addTagToAmazonLink({ href, tag, override });
  }

  // Use 96128X1550925 on Today, 96128X1608993 on News, default to Today
  let skimlinksID = '96128X1550925';
  if (typeof canonicalUrl === 'string' && canonicalUrl.includes('nbcnews.com')) {
    skimlinksID = '96128X1608993';
  }

  // E.g. expect https://go.redirectingat.com/?xs=1&id=96128X1550925&url=https%3A%2F%2Fwww.bedbathandbeyond.com%2Fstore%2Fproduct%2Fpure-beech-reg-100-modal-sateen-sheet-set%2F3257516
  const url = [
    `https://go.redirectingat.com/?xs=1&id=${skimlinksID}`,
    `url=${encodeURIComponent(href)}`,
  ];

  if (canonicalUrl) {
    url.push(`sref=${encodeURIComponent(canonicalUrl)}`);
  }

  return url.join('&');
}
