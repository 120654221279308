import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Picture } from 'components/Picture';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import styles from './SponsoredTip.module.scss';

const sponsoredTip = 'SponsoredTip';
const sponsoredTipImg = 'SponsoredTipImg';
const sponsoredTipContent = 'SponsoredTipContent';

export const SponsoredTip = ({ widget }) => {
  const { properties } = widget;
  const description = properties['text-description'].replace(/%20/gi, ' ');

  return (
    <div className={cx(sponsoredTip, 'df flex-row-m flex-column', styles.sponsoredTip)}>
      <div className={cx(sponsoredTipImg, styles.image, 'mt7-m mt6')}>
        <Picture
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_1520x760,
            m: AIMS_FLAVORS.FOCAL_240x240,
            l: AIMS_FLAVORS.FOCAL_300x300,
            xl: AIMS_FLAVORS.FOCAL_300x300,
          }}
          url={properties['sponsor-image-source']}
          flavor="focal"
          alt="sponsor"
        />
      </div>
      <div className={cx(sponsoredTipContent, styles.content, 'mr7 mt5-m')}>
        <h3 className={cx(styles.text, 'f5 white ml5 mb1')}>{ i18next.t('SPONSORED') }</h3>
        <div />
        <p className="white ml5 f4">{description}</p>
      </div>
    </div>
  );
};

SponsoredTip.propTypes = {
  widget: PropTypes.shape().isRequired,
};
