import React from 'react';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';

export class Pinterest extends React.Component {
  static propTypes = {
    widget: CustomPropTypes.widget.isRequired,
  };

  componentDidMount() {
    loadScript(
      '//assets.pinterest.com/js/pinit.js',
      {
        attributes: {
          defer: true,
          'data-pin-build': 'parsePinBtns', // expose internal pinterest build function
        },
      },
    )
      // re-running `.process` here since in SPA mode, the script needs to re-parse
      // embeds on the page when navigating to a new article
      .then(() => window.parsePinBtns && window.parsePinBtns(this.embed));
  }

  render() {
    const { widget } = this.props;

    // TODO move script-strip to class all other widgets can extend.
    // Strip script tags from content.
    const baseline = htmlSetIframeLoadingAttributes(
      widget.baseline.replace(
        /<script[^<]+src="([^"]+)"[^<]*><\/script>/gi,
        '',
      ),
      'lazy',
    );

    /* eslint-disable react/no-danger */
    return (
      <div
        ref={(el) => { this.embed = el; }}
        dangerouslySetInnerHTML={{ __html: baseline }}
      />
    );
    /* eslint-enable react/no-danger */
  }
}
