import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { decodeString } from 'lib/stringUtils';

import './styles.themed.scss';

const block = 'liftout';

function LiftOut({ size, text }) {
  if (!text) {
    return null;
  }

  return (
    <div
      className={classNames(
        block,
        {
          [`${block}--small`]: size === 'small',
          [`${block}--large`]: size === 'large',
        },
      )}
      data-testid="liftout"
    >
      <p className={`${block}__text`}>
        {decodeString(text)}
      </p>
    </div>
  );
}

LiftOut.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string.isRequired,
};

LiftOut.defaultProps = {
  size: 'small',
};

export default LiftOut;
