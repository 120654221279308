import React from 'react';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';

export class NbcAffiliatesVideoLegacy extends React.Component {
  static propTypes = {
    widget: CustomPropTypes.widget.isRequired,
  };

  componentDidMount() {
    const { widget } = this.props;

    // loading the script this way since their script (annoyingly) uses the script tag's
    // parent container to determine where the iframe should be inserted
    if (widget.properties?.['video-source']) {
      loadScript(widget.properties['video-source'], { location: this.elem });
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        className="embed-widget__use-presentation"
        ref={(div) => { this.elem = div; }}
      />
    );
  }
}
