import React from 'react';
import get from 'lodash.get';

import { isMSNBCDaily } from 'lib/article';
import { getCanonical } from 'lib/urlUtils';

import Recommended from 'components/Recommended';
import { RecircBody as MsnbcDailyRecirc } from 'components/MsnbcDaily/Recirc/Body';

function getRecommendationComponent(item, i, vertical) {
  const hideRecommendations = get(item, ['content', 'presentation', 'hideRecommendations'], false);
  if (hideRecommendations) {
    return null;
  }

  if (vertical === 'select') {
    return null;
  }

  const {
    content: {
      taxonomy,
      url,
    } = {},
    mobile,
  } = item;

  // MSNBC vertical and MSNBC Opinion taxonomy
  if (isMSNBCDaily(vertical, taxonomy)) {
    // Show single MSNBC in-body recommendation unit
    if (mobile) {
      return <MsnbcDailyRecirc key={i} pageRegion="article-body" />;
    }
    return null;
  }

  return (
    <Recommended
      key={i}
      mobile={mobile}
      taxonomy={taxonomy}
      url={getCanonical(url)}
      pageRegion="article-body"
    />
  );
}

export { getRecommendationComponent };
