import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import MobileApi from 'lib/MobileApi';
import { related as relatedPropType } from 'lib/CustomPropTypes';
import { TXT_IMG_TANDEM_HOVER, RECIRC_SAVE_BUTTON } from 'lib/brandFeatures';


import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import Thumbnail from 'components/Thumbnail';
import { Save } from 'components/SocialShareMenu/Save';

import './styles.themed.scss';

const mapStateToProps = ({
  shared,
}) => ({
  vertical: shared.vertical,
  isChromeless: shared.isChromeless,
});

@connect(mapStateToProps)
class RelatedItem extends React.Component {
  static propTypes = {
    item: relatedPropType.isRequired,
    linkTracking: PropTypes.string.isRequired,
    singleItem: PropTypes.bool.isRequired,
    vertical: PropTypes.string.isRequired,
    isChromeless: PropTypes.bool,
    isTrending: PropTypes.bool,
    isNBCNewsCommerce: PropTypes.bool,
  };

  static defaultProps = {
    isChromeless: false,
    isTrending: false,
    isNBCNewsCommerce: false,
  };


  getUnibrow = (linkStyle, spanStyle) => {
    const { item: { url, unibrow, label } } = this.props;
    let text;
    let href;
    if (this.isThinkContent()) {
      // Think article
      text = 'Opinion';
      href = '/opinion';
    } else if (unibrow?.text && unibrow.url?.primary) {
      // Unibrow data
      ({ text } = unibrow);
      href = unibrow.url.primary;
    } else if (label) {
      // Related link label
      text = label;
      href = url;
    } else {
      // 'Compute' an eyebrow label
      text = url.split('/')[3]; // eslint-disable-line prefer-destructuring
      href = this.isExternalURL() ? url : `/${text}`;
    }
    return (
      <a className={linkStyle} href={href}>
        <span className={spanStyle}>{text}</span>
      </a>
    );
  }

  // Related item is editorially controlled and can be linked off site
  isExternalURL = () => {
    const { item: { url } } = this.props;
    return !/\.(nbcnews|think|msnbc)\.com/.test(url);
  }

  // Test if url matches a think url
  isThinkContent = () => {
    const { item: { url } } = this.props;
    return /nbcnews.com\/think\//.test(url);
  }

  sizes = () => {
    const { singleItem, vertical } = this.props;

    if (singleItem) {
      const singleShared = {
        m: AIMS_FLAVORS.FOCAL_280x140,
        xl: AIMS_FLAVORS.FOCAL_320x160,
      };

      if (['msnbc', 'select'].includes(vertical)) {
        return { s: AIMS_FLAVORS.FOCAL_80x80, ...singleShared };
      }

      return { s: AIMS_FLAVORS.FOCAL_60x60, ...singleShared };
    }

    if (vertical === 'msnbc') {
      return {
        s: AIMS_FLAVORS.FOCAL_80x80,
      };
    }

    return {
      s: AIMS_FLAVORS.FOCAL_60x60,
      m: AIMS_FLAVORS.FOCAL_80x80,
    };
  }

  render() {
    const {
      item,
      isChromeless,
      isTrending,
      isNBCNewsCommerce,
      linkTracking,
      singleItem,
      vertical,
    } = this.props;

    const {
      title, type, thumbnail, id, url, label,
    } = item;

    const hasTandemHover = getFeatureConfigForBrand(
      TXT_IMG_TANDEM_HOVER,
      vertical,
    );

    const hasSaveButton = getFeatureConfigForBrand(
      RECIRC_SAVE_BUTTON,
      vertical,
    );

    const itemClasses = classNames(
      'related-item',
      {
        'related-item__default': !isTrending && !isNBCNewsCommerce && vertical === 'today',
        'related-item__is-trending': isTrending || isNBCNewsCommerce,
        'related-item__is-nbc-news-commerce': isNBCNewsCommerce,
        'related-item__single': singleItem,
        'related-item__no-art': !thumbnail,
        'related-item__has-tandem-hover': hasTandemHover,
      },
    );

    // Regular link
    let Element = Link;
    let options = {
      to: url,
      icid: linkTracking,
    };

    // Trigger native playback for videos in Chromeless
    if (type === 'video' && id && isChromeless) {
      Element = 'button';
      options = {
        onClick: () => {
          MobileApi.viewVideo(id);
        },
      };
    }

    return (
      <div className={itemClasses}>
        {thumbnail ? (
          <div
            className="related-item__thumbnail-wrapper"
          >
            {hasSaveButton ? (
              <Save
                contentId={id}
                contentType={type}
                options={{ isThumbnail: true, offset: 's', showCTA: true }}
              />
            ) : null}
            <Element
              {...options}
              className={classNames('related-item__link related-item__picture-link')}
            >
              <Thumbnail
                responsiveFlavors={this.sizes()}
                additionalStyles={{
                  raw: 'related-item__thumbnail__image--raw',
                  thumbnail: 'related-item__thumbnail__image',
                }}
                thumbnail={thumbnail}
                label={label}
                type={type}
                url={url}
                originalWidth={60}
                originalHeight={60}
              />
            </Element>
            <span className={classNames('related-item__eyebrow', { think: this.isThinkContent() })}>
              {this.getUnibrow('related-item__label', null)}
              <Element {...options}>
                <TypeIcon
                  type={type}
                  className="related-item__icon"
                  radius={vertical === 'today'}
                />
              </Element>
            </span>
          </div>
        ) : null}
        <div className="related-item__info-wrapper">
          <h3 className="related-item__title">
            {
              this.getUnibrow(
                classNames({ think: this.isThinkContent() }),
                'related-item__eyebrow',
              )
            }
            <Element
              {...options}
              className="related-item__link"
            >
              {title}
            </Element>
          </h3>
          {hasSaveButton && !thumbnail ? (
            <Save
              contentId={id}
              contentType={type}
              options={{ isTitle: true }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default RelatedItem;
