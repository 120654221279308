import {
  extractTermPaths,
} from 'lib/taxonomy';

/**
 * Format a taxonomy condition - turn a string into an array of values.
 * @param {string} input
 * @returns {array}
 */
const formatTaxCondition = (input) => {
  if (typeof input === 'string' && input.length > 0) {
    return input.split(/\n/);
  }
  return [];
};

/**
 * Map callback formatting function.
 */
const mapFormat = ({
  excludedTaxonomyPaths,
  includedTaxonomyPaths,
  newsletterBrand,
  newsletterDescription,
  newsletterKey,
  newsletterSignupSource,
  newsletterTitle,
  newsletterTeaseImageUrl,
}) => ({
  excluded: formatTaxCondition(excludedTaxonomyPaths),
  included: formatTaxCondition(includedTaxonomyPaths),
  newsletterBrand,
  newsletterDescription,
  newsletterKey,
  newsletterSignupSource,
  newsletterTitle,
  newsletterTeaseImageUrl,
});

/**
 * Find a matching newsletter configuration.
 * @param {array} taxonomy
 * @param {array} newsletterData
 * @returns
 */
const getInlineNewsletterConfig = (
  taxonomy = [],
  newsletterData = [],
) => {
  // Extract just path values from array of taxonomy objects.
  const termPaths = extractTermPaths(taxonomy);

  // Compare provided terms to content's terms.
  const termCompare = (compare) => compare.filter((val) => termPaths.includes(val)).length;

  // Create new array from formatted data.
  const formatted = newsletterData.map(mapFormat);

  // Filter configurations where taxonomy has been explicitly excluded or not included.
  const filtered = formatted.filter((config) => {
    // Remove any matching exclusion OR no matching inclusion
    if (termCompare(config.excluded) > 0 || termCompare(config.included) === 0) {
      return false;
    }
    // Allow
    return true;
  });

  // Sort newsletter configurations by matched inclusions to find best match.
  const sorted = filtered.sort((a, b) => {
    const aI = termCompare(a.included);
    const bI = termCompare(b.included);
    if (aI === bI) {
      return 0;
    }
    return aI < bI ? -1 : 1;
  });

  // Return last, most relevant configuration.
  return sorted.length ? sorted.pop() : null;
};

export {
  formatTaxCondition,
  getInlineNewsletterConfig,
  mapFormat,
};
