import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import IntObserver from 'components/IntObserver';
import classNames from 'classnames';
import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import {
  article as ArticlePropType,
  newsletter as NewsletterPropType,
} from 'lib/CustomPropTypes';
import { stub as $t } from '@nbcnews/analytics-framework';
import { isBlogArticle } from 'lib/article';

import { getNewsletterData as getNewsletterDataAction } from 'redux/modules/newsletter';

import { SignupInput } from '../SignupInput';
import { pickSiteKey } from '../verticalLogic';
import { getInlineNewsletterConfig } from '../utils/getInlineNewsletterConfig';
import './styles.themed.scss';

$t('register', 'newsletter_subscribe');
$t('register', 'InlineNewsletterModule_Reached');

const block = 'nl-signup-inline';

const mapStateToProps = (
  {
    shared,
    article,
    newsletter,
  }, { vertical },
) => ({
  article: get(article, 'content[0]') || null,
  newsletterData: get(newsletter, ['newsletterData', 'Newsletters'], []),
  vertical: vertical || shared.vertical,
  isChromeless: shared.isChromeless,
});

const mapActionToProps = (dispatch) => ({
  getNewsletterData: () => dispatch(getNewsletterDataAction()),
});

@connect(mapStateToProps, mapActionToProps)
class NewsletterSignupInline extends React.Component {
  static propTypes = {
    article: ArticlePropType.isRequired,
    getNewsletterData: PropTypes.func,
    newsletterData: PropTypes.arrayOf(NewsletterPropType),
    vertical: PropTypes.string.isRequired,
    isChromeless: PropTypes.bool.isRequired,
    shouldThumbnailRender: PropTypes.bool,
  }

  static defaultProps = {
    getNewsletterData: Function.prototype,
    newsletterData: [],
    shouldThumbnailRender: true,
  }

  async componentDidMount() {
    const { getNewsletterData } = this.props;

    await getNewsletterData();

    requestAnimationFrame(() => {
      // Newsletter embed heights are different based on the text set in the
      // configuration. To accomodate this dynamic height, send the height
      // of the body to the parent window
      // https://amp.dev/documentation/components/amp-iframe/#iframe-resizing
      window.parent.postMessage(
        {
          sentinel: 'amp',
          type: 'embed-size',
          height: document.body.scrollHeight,
        },
        '*',
      );
    });
  }

  handleObserverCallback = ([observer]) => {
    const { isIntersecting } = observer;
    if (isIntersecting) {
      $t('track', 'InlineNewsletterModule_Reached', true);
    }
  }

  onSubmit = (id, placement) => {
    if (id && placement) {
      $t('track', 'newsletter_subscribe', { id, placement });
    }
  };

  render() {
    const {
      article,
      article: {
        nativeAd,
        taxonomy = [],
      },
      isChromeless,
      newsletterData,
      vertical,
      shouldThumbnailRender = true,
    } = this.props;

    // Do not show signup under these conditions:
    if (isChromeless || nativeAd || isBlogArticle(article)) {
      return null;
    }

    const sitekey = pickSiteKey(vertical);

    const newsletterConfig = getInlineNewsletterConfig(taxonomy, newsletterData);

    if (!newsletterConfig) {
      return null;
    }

    const newsletterTitle = get(newsletterConfig, 'newsletterTitle', null);
    const newsletterDescription = get(newsletterConfig, 'newsletterDescription', null);
    const newsletterTeaseImageUrl = get(newsletterConfig, 'newsletterTeaseImageUrl', null);
    const newsletterKey = get(newsletterConfig, 'newsletterKey', null);
    const newsletterSignupSource = get(newsletterConfig, 'newsletterSignupSource', null);

    const computedValues = {
      teaseImage: {
        url: {
          primary: newsletterTeaseImageUrl,
        },
      },
    };

    const shouldRenderThumbnailVariant = shouldThumbnailRender && newsletterTeaseImageUrl;

    return (
      <IntObserver
        callback={this.handleObserverCallback}
        threshold={0.25}
      >
        <div className={classNames(block, {
          [`${block}--with-picture`]: shouldRenderThumbnailVariant,
        })}
        >
          {shouldRenderThumbnailVariant && <div className={`${block}__header`}>Email Newsletters</div>}

          <div className={classNames(`${block}__container`, {
            [`${block}__container--with-picture`]: shouldRenderThumbnailVariant,
          })}
          >
            <div className={classNames(`${block}__metadata`, {
              [`${block}__metadata--with-picture`]: shouldRenderThumbnailVariant,
            })}
            >
              {shouldRenderThumbnailVariant && (
                <div className={`${block}__picture-container`}>
                  <Picture
                    className={`${block}__picture`}
                    responsiveFlavors={{
                      s: AIMS_FLAVORS.FOCAL_460x460,
                      m: AIMS_FLAVORS.FOCAL_300x300,
                      xl: AIMS_FLAVORS.FOCAL_360x360,
                    }}
                    width={460}
                    height={460}
                    computedValues={computedValues}
                  />
                </div>
              )}

              <div className={`${block}__details-container`}>
                <div className={`${block}__details`}>
                  {newsletterTitle && (
                    <p className={classNames(`${block}__title`, {
                      [`${block}__title--with-picture`]: shouldRenderThumbnailVariant,
                    })}
                    >
                      {newsletterTitle}
                    </p>
                  )}

                  {newsletterDescription && (
                    <p className={classNames(`${block}__description`, {
                      [`${block}__description--with-picture`]: shouldRenderThumbnailVariant,
                    })}
                    >
                      {newsletterDescription}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <SignupInput
              grayscale={shouldRenderThumbnailVariant}
              newsletterId={newsletterKey}
              onSubmit={() => this.onSubmit(newsletterKey, newsletterSignupSource)}
              signupSource={newsletterSignupSource}
              sitekey={sitekey}
              vertical={vertical}
            />
          </div>
        </div>
      </IntObserver>
    );
  }
}

export { NewsletterSignupInline };
