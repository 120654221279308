import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import classNames from 'classnames';

import { loadMenu } from 'redux/modules/menu';
import { menuItem as menuItemPropType } from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { HAS_CUSTOM_TITLE } from 'lib/brandFeatures';
import { parseIdFromUrl } from 'lib/parseIdFromUrl';
import { appendSearchParam } from 'lib/link';

import Link from 'components/Link';
import { Button } from 'components/Button';
import './styles.themed.scss';
import { getDataActivityMapID } from 'lib/articleUtils';

const mapStateToProps = ({ article, menu, shared }) => ({
  articleId: article.content?.[0]?.id,
  menu: menu.current,
  vertical: shared.vertical,
});

const mapActionsToProps = (dispatch) => ({
  loadEmbedMenu: (id) => dispatch(loadMenu(id)),
});

@connect(mapStateToProps, mapActionsToProps)
class MenuEmbed extends React.Component {
  static propTypes = {
    articleId: PropTypes.string,
    html: PropTypes.string,
    menu: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      menuItems: PropTypes.arrayOf(menuItemPropType),
    }),
    loadEmbedMenu: PropTypes.func,
    className: PropTypes.string,
    vertical: PropTypes.string.isRequired,
  };

  static defaultProps = {
    articleId: '',
    html: null,
    menu: {},
    loadEmbedMenu: () => { },
    className: 'menu-embed',
  };

  componentDidMount() {
    const { html, loadEmbedMenu } = this.props;
    const menuId = this.getMenuId(html);
    loadEmbedMenu(menuId);
  }

  getMenuId = (html) => {
    const { vertical } = this.props;
    const match = html.match(/embedId=["“”]([0-9]+)["“”]/);
    const menuId = match?.[1];

    if (!menuId) return null;

    return vertical === 'today' ? `tdmn${menuId}` : `ncmn${menuId}`;
  };

  renderMenuItems = (menuItems) => (
    menuItems.map((item, i) => {
      const { articleId } = this.props;

      const url = get(item, 'url.primary', null);
      const linkId = parseIdFromUrl(url);
      const { text = '' } = item;

      if (articleId === linkId) return null;

      if (i > 6) return null;

      if (i === 6) {
        return this.renderSeeMoreButton(url, i);
      }

      const icid = `relatedList_${i + 1}`;
      return (
        <li className="menu-embed__link" key={`${i + url}`}>
          <Link href={appendSearchParam(url, { icid })} icid={icid}>
            { /* 100 char max */}
            {text.slice(0, 100)}
          </Link>
        </li>
      );
    })
  );

  renderSeeMoreButton = (url, i) => (
    <Button
      key={i + url}
      size="normal"
      type="link"
      url={url}
      title="See More"
    />
  );

  render() {
    const { menu, className } = this.props;
    const { name = '', menuItems = [] } = menu || {};
    const { vertical } = this.props;
    const hasCustomTitle = getFeatureConfigForBrand(
      HAS_CUSTOM_TITLE,
      vertical,
    );

    // 30 char max
    const shortenedTitle = name.slice(0, 30);

    const dataActivityMapID = getDataActivityMapID(
      {
        componentName: 'menu-embed',
        pageRegion: 'article-body',
        componentTitle: shortenedTitle,
      },
    );

    return menuItems.length ? (
      <div className={className} data-activity-map={dataActivityMapID} data-test="menu-embed" data-testid="menu-embed">
        <h4 className={classNames('menu-embed__title', {
          'menu-embed__title--default': !hasCustomTitle,
          'menu-embed__title--custom': hasCustomTitle,
        })}
        >
          {shortenedTitle}
        </h4>
        <ul className="menu-embed__items">
          {this.renderMenuItems(menuItems)}
        </ul>
      </div>
    ) : null;
  }
}

export default MenuEmbed;
